import dayjs from "dayjs";
import pricingStatuses from "@/models/pricingStatuses";
import { ProfilePicture } from "@/bapi-client/types/users";
import { AttachmentResponse } from "@/bapi-client/types/common/attachment";
import {
  ActivityRecordResponse,
  EquipmentLengthType,
  OtherCostItem,
  PricingUnitType,
  QuoteEquipment,
  QuoteData,
  QuoteResponse,
} from "@/bapi-client/types/pricing";

export interface QuoteNote {
  id: string;
  quoteId: string;
  note: string;
  timestamp: string;
  userId?: string;
  userName: string;
  userInitials: string;
  isInternal: boolean;
  companyBusinessName?: string;
  attachments: AttachmentResponse[];
  profilePicture: ProfilePicture;
}

export interface ActivityRecord {
  id: string;
  userEmail: string;
  userName: string | null;
  timestamp: string;
  activity: number;
}

export interface Shipper {
  id: string;
  name: string;
  customerId: string;
  description: null | string;
}

export interface Quote {
  id?: string;
  email: string;
  customerBusinessName?: string;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  price?: number;
  fscCost?: number;
  effectiveDate: string;
  expirationDate: string;
  createdAt: string;
  lastModified: string;
  statusCode?: number;
  rejectedOnce?: boolean;
  customerId?: string;
  customerName?: string;
  shipper: Shipper;
  shipperName: string;
  deleted?: boolean;
  shortLink?: string;
  quoteUrl?: string;
  otherCosts: OtherCostItem[];
  hasNotes: boolean;
  data: QuoteData;
  statusText: string;
  commodity: string;
  notes?: Record<string, any>[];
}

export function makeQuote(data: QuoteResponse): Quote {
  return {
    id: data.id,
    email: data.email,
    customerBusinessName: data.customer_business_name,
    price: data.price,
    fscCost: data.fsc_cost,
    effectiveDate: data.effective_date,
    expirationDate: data.expiration_date,
    createdAt: data.created_at ? dayjs(data.created_at).toISOString() : "",
    lastModified: dayjs(data.last_modified).toISOString(),
    statusCode: data.status,
    rejectedOnce: data.rejected_once ?? false,
    customerId: data.customer_id ?? "",
    customerName: data.customer_name ?? "",
    deleted: data.deleted ?? false,
    shortLink: data.short_link ?? "",
    otherCosts: data.other_costs ?? [],
    hasNotes: data.has_notes ?? false,
    data: data.data,
    statusText: pricingStatuses[data.status ?? 49],
    commodity: `${data.data.stccs[0]?.associated_stcc} - ${data.data.stccs[0]?.commodity}`,
    originCity: data.data.origin_city,
    originState: data.data.origin_state,
    destinationState: data.data.destination_state,
    destinationCity: data.data.destination_city,
    quoteUrl: data.quote_url,
    shipper: {
      id: data.shipper?.id,
      name: data.shipper?.name,
      description: data.shipper?.description,
      customerId: data.shipper?.customer_id,
    },
    shipperName: data.shipper?.name,
  };
}

export function makeActivityRecord(data: ActivityRecordResponse): ActivityRecord {
  return {
    id: data.id,
    userEmail: data.user_email,
    userName: data.user_name,
    timestamp: data.activity_timestamp,
    activity: data.activity_code,
  };
}

export interface QuoteForm {
  shipperId: string;
  email: string;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  stcc: string;
  rule11: boolean;
  privateCar: boolean;
  system: boolean;
  equipmentType: QuoteEquipment | "";
  equipmentLength: EquipmentLengthType;
  miles: number;
  volume: number;
  volumeType: PricingUnitType;
  effectiveDate: string;
  expirationDate: string;
}
