import { ref } from "vue";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { BAPICustomerSupportTicket } from "@/bapi-client/types/customer-support";
import useNotificationStore from "@/stores/notifications";

export function useCSTickets() {
  const tickets = ref<BAPICustomerSupportTicket[]>([]);

  async function getTickets(
    customerId: string,
    filters?: Record<string, string>,
    sortColumn?: string,
    sortDirection?: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    const notifier = useNotificationStore();
    notifier.setLoading("Loading tickets");
    const response = await useBapi(
      BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKETS,
      customerId,
      filters,
      sortColumn,
      sortDirection,
      pageNumber,
      pageSize,
    );
    notifier.setLoading();
    if (!response.success) {
      notifier.setToast("danger", "Failed to load tickets");
      console.error("Failed to fetch tickets", response.error);
      return;
    }
    tickets.value = response.data.tickets;
    return response.data;
  }

  return {
    tickets,
    getTickets,
  };
}
