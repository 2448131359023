import { RouteRecordRaw } from "vue-router";
import { useAnalyticsStore } from "@/stores/analytics";

const AnalyticsPage = () => import("@/pages/analytics/AnalyticsPage.vue");
const AdvancedAnalyticsPage = () => import("@/pages/analytics/AdvancedAnalyticsPage.vue");

const analyticsRoutes: RouteRecordRaw = {
  name: "analyticsPage",
  component: AnalyticsPage,
  path: "/analytics/:companyId",
  meta: {
    authenticated: true,
  },
  children: [
    {
      name: "advancedAnalytics",
      component: AdvancedAnalyticsPage,
      path: "/analytics/:companyId/advanced",
      meta: {
        authenticated: true,
        title: `Analytics`,
      },
      beforeEnter: (to) => {
        // Edge case, user arrives without query param
        if (!to.query.report) {
          const analytics = useAnalyticsStore();
          const url = analytics.views[0].url;
          return {
            name: "advancedAnalytics",
            params: { companyId: to.params.companyId },
            query: { report: encodeURIComponent(url) },
          };
        }
        const store = useAnalyticsStore();
        store.setView(decodeURIComponent(to.query.report as string));
      },
    },
  ],
};

export default analyticsRoutes;
