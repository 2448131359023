/**
 * Converts a dollar amount to cents using BigInt for precision.
 * @param amount string representing dollars and cents.
 * @returns The equivalent cents as a BigInt.
 */
export function dollarsToCents(amount: string | number): bigint {
  return BigInt(Math.round(Number(amount) * 100));
}

/**
 * Converts a cent amount to a precise dollar amount as a number.
 * @param cents A number or bigint representing cents.
 * @returns The equivalent dollar amount as a number with proper rounding.
 */
export function centsToDollars(cents: number | bigint): number {
  return Number(BigInt(cents)) / 100;
}

/**
 * Formats a dollar amount using the Intl.NumberFormat API.
 * @param amount A string representing a dollar amount.
 * @returns The formatted dollar string
 */
export function formatDollars(amount: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}
