import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import { Result } from "@/bapi-client/types";
import { NotificationsSettings, NotificationSettingsResponse } from "@/bapi-client/types/notifications";

export async function getNotificationSettings(customerId: string): Promise<Result<NotificationSettingsResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.NOTIFICATIONS_GET_SETTINGS,
    "GET",
    `/notifications/${customerId}/settings`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return parsed;
}

export async function updateNotificationSettings(
  customerId: string,
  body: NotificationsSettings,
): Promise<Result<NotificationSettingsResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.NOTIFICATIONS_UPDATE_SETTINGS,
    "POST",
    `/notifications/${customerId}/settings`,
    {
      body,
    },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return parsed;
}
