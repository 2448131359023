import { ref, watch } from "vue";
import wabtecLogo from "@/assets/sidebar-logo-wabtec.svg";
import tgLogo from "@/assets/tg_logo_outline_cropped.png";
const activeTheme = ref<"dev" | "prod" | "wabtec">();

const logoUrl = ref();
const pageTitle = ref("");

export function useTheme() {
  if (!activeTheme.value) {
    setDefaultTheme();
  }

  watch(
    activeTheme,
    (next) => {
      logoUrl.value = next === "wabtec" ? wabtecLogo : tgLogo;
      pageTitle.value = next === "wabtec" ? "RailConnect - Enhanced Visibility" : "Telegraph";
      const link = document.querySelector("link[rel='icon']");
      if (link) {
        if (next === "wabtec") {
          link.setAttribute("href", "/wabtec_favicon.ico");
        } else {
          link.setAttribute("href", "/favicon.ico");
        }
      }
    },
    { immediate: true },
  );

  function setDefaultTheme() {
    if (window.location.origin.includes("railconnect")) {
      setTheme("wabtec");
    } else if (import.meta.env.PROD) {
      setTheme("prod");
    } else if (import.meta.env.DEV) {
      setTheme("dev");
    }
  }

  function setTheme(theme: "dev" | "prod" | "wabtec") {
    activeTheme.value = theme;
    const html = document.querySelector("html");
    if (html) {
      html.setAttribute("data-theme", theme);
    }
  }

  function setTitle(title: string) {
    document.title = `${pageTitle.value} ${title}`;
  }

  return { activeTheme, setDefaultTheme, setTheme, logoUrl, setTitle };
}
