//takes a formkit node instance, accesses the .value which is whatever was typed in the input
import { FormKitNode } from "@formkit/core";

export function isValidEmail(node: FormKitNode): boolean {
  const emailString: string = node.value as unknown as string;
  const emailArray = emailString.split(",");
  let allValid = true;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  for (let i = 0; i < emailArray.length; i++) {
    const valid = emailRegex.test(emailArray[i].trim());
    if (!valid) {
      allValid = false;
      break;
    }
  }
  return allValid;
}
