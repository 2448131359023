import { RouteRecordRaw } from "vue-router";

const OAuthLoginPage = () => import("@/pages/login/OAuthLoginPage.vue");
const OAuthCallbackPage = () => import("@/pages/login/OAuthCallbackPage.vue");
const OAuthLogoutPage = () => import("@/pages/login/OAuthLogoutPage.vue");
const OAuthLoginFailedPage = () => import("@/pages/login/OAuthLoginFailedPage.vue");

export const oAuthLoginRoutes: RouteRecordRaw = {
  name: "oAuthLoginPage",
  path: "/auth",
  meta: {
    authenticated: false,
  },
  children: [
    {
      name: "oauthCallback",
      path: "callback",
      component: OAuthCallbackPage,
      meta: {
        title: "OAuth Callback",
      },
      props: true,
    },
    {
      name: "oauthLogin",
      path: "login",
      component: OAuthLoginPage,
      meta: {
        title: "OAuth Login",
      },
      props: true,
    },
    {
      name: "oauthLogout",
      path: "logout",
      component: OAuthLogoutPage,
      meta: {
        title: "OAuth Logout",
      },
      props: true,
    },
    {
      name: "oauthLoginFailed",
      path: "failed",
      component: OAuthLoginFailedPage,
      meta: {
        title: "OAuth Login Failed",
      },
      props: true,
    },
  ],
};
