import * as Sentry from "@sentry/vue";
import { BAPICommand } from "./types/commands";
import { BAPIError } from "./errors";
import { BAPIResponse } from "./types/bapiResponse";
import type { Result } from "./types";
import { BAPIParams } from "./types/bapiParams";
const OPEN_URLS = ["/shippers", "/user/login", "/user/forgot", "/user/reset"] as const;
type ALLOWED_METHODS = ["GET", "POST", "PUT", "DELETE", "PATCH"];
type AllowedMethod = ALLOWED_METHODS[number];

interface FetchOptions<C extends keyof BAPIParams> {
  headers?: Record<string, string>;
  body?: BAPIParams[C];
  canAbort?: true;
}

interface AFetchOptions<C extends keyof BAPIParams> extends FetchOptions<C> {
  canAbort: true;
}

interface RequestInit {
  // @TODO: This is not only the incorrect type,
  // but we can derive the correct type and stringify the body
  // within the fetch handler.
  body?: Blob | FormData | string;
  headers: Headers;
  method: AllowedMethod;
  signal?: AbortSignal;
}

interface FResponse<C extends BAPICommand> {
  json: () => Promise<Result<BAPIResponse[C]>>;
  blob: () => Promise<Result<Blob>>;
  raw: () => Response;
}

export const abortableRequests: Map<BAPICommand, AbortController> = new Map();
const basePath = import.meta.env.VITE_API_URL || "https://prod-b-api.telegraph.io";

export function abort(command: BAPICommand) {
  if (abortableRequests.has(command)) {
    abortableRequests.get(command)?.abort();
  }
}

export async function fetchHandler<TC extends BAPICommand>(
  command: Extract<BAPICommand, TC>,
  method: AllowedMethod,
  path: string,
): Promise<Result<FResponse<typeof command>>>;
export async function fetchHandler<TC extends BAPICommand>(
  command: Extract<BAPICommand, TC>,
  method: AllowedMethod,
  path: string,
  opts: FetchOptions<TC>,
): Promise<Result<FResponse<typeof command>>>;
export async function fetchHandler<TC extends BAPICommand>(
  command: Extract<BAPICommand, TC>,
  method: AllowedMethod,
  path: string,
  opts: AFetchOptions<TC>,
): Promise<Result<FResponse<typeof command>>>;
export async function fetchHandler<T extends FetchOptions<BAPICommand> | AFetchOptions<BAPICommand> | undefined>(
  command: BAPICommand,
  method: AllowedMethod,
  path: string,
  opts?: T,
): Promise<Result<FResponse<typeof command>>> {
  const headers = new Headers();
  const requireAuth = !path.startsWith("http") && !OPEN_URLS.some((url) => path.startsWith(url));

  if (requireAuth) {
    headers.set("Authorization", `Bearer ${localStorage.getItem("authToken")}`);
  }

  if (opts?.headers) {
    for (const header in opts.headers) {
      headers.set(header, opts.headers[header]);
    }
  }

  if (!opts?.headers?.["Content-Type"]) {
    headers.set("Content-Type", "application/json");
  }

  const fullPath = path.startsWith("http") ? path : basePath + path;
  const requestInit: RequestInit = {
    headers,
    method,
  };

  if (opts?.body) {
    requestInit.body =
      opts.body instanceof Blob || opts.body instanceof FormData
        ? opts.body
        : JSON.stringify(opts.body as BAPIParams[typeof command]);
  }

  if (opts !== undefined && "canAbort" in opts) {
    const controller = new AbortController();
    requestInit.signal = controller.signal;
    abortableRequests.set(command, controller);
  }

  const request = new Request(fullPath, requestInit);

  const fetchResult = await fetch(request).catch((error) => {
    if (error instanceof DOMException) {
      if (error.name === "AbortError") {
        const E = new BAPIError(`${command} request was aborted.`, undefined, error, true);
        return E;
      }
      const E = new BAPIError(`${command} request failed to send from client.`, undefined, error);
      Sentry.captureException(E);
      return E;
    }
    const E = new BAPIError(`${command} request failed to send from client.`, undefined, error);
    Sentry.captureException(E);
    return E;
  });
  if (fetchResult instanceof BAPIError) {
    return { success: false, error: fetchResult };
  }
  if (fetchResult instanceof Response && fetchResult.status >= 400) {
    let E: BAPIError;
    try {
      E = await fetchResult.json();
    } catch (error) {
      E = new BAPIError(`Failed to parse JSON for command ${command}`, undefined, error as Error);
      Sentry.captureException(E);
    }
    Sentry.captureException(E);
    return {
      success: false,
      error: E,
    };
  }
  return {
    success: true,
    data: {
      async json(): Promise<Result<BAPIResponse[typeof command]>> {
        if (!fetchResult.ok) {
          return {
            success: false,
            error: new BAPIError("Attempted to parse JSON on a non-200 response.", fetchResult.status),
          };
        }
        const parsed: BAPIResponse[typeof command] | BAPIError = await fetchResult.json().catch((error) => {
          const E = new BAPIError(`Failed to parse JSON for command ${command}`, undefined, error);
          Sentry.captureException(E);
          return E;
        });

        if (parsed instanceof BAPIError) {
          return { success: false, error: parsed };
        }

        return { success: true, data: parsed };
      },
      async blob() {
        if (!fetchResult.ok) {
          const E = new BAPIError("Attempted to parse binary blob on a non-200 response.", fetchResult.status);
          Sentry.captureException(E);
          return { success: false, error: E };
        }

        const parsed: Blob | BAPIError = await fetchResult.blob().catch((error) => {
          const E = new BAPIError(`Failed to parse blob for command ${command}`, undefined, error);
          Sentry.captureException(E);
          return E;
        });

        if (parsed instanceof BAPIError) {
          return { success: false, error: parsed };
        }

        return { success: true, data: parsed };
      },
      raw() {
        return fetchResult;
      },
    },
  };
}
