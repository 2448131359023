import { RouteRecordRaw } from "vue-router";
const PricingDashboardPage = () => import("@/pages/pricing/partner/PricingDashboard.vue");
const PricingEditQuote = () => import("@/pages/pricing/partner/PricingQuoteBuilder.vue");
const PricingPage = () => import("@/pages/pricing/partner/PricingPage.vue");

const routes: RouteRecordRaw = {
  name: "pricing",
  path: "/pricing/",
  props: true,
  component: PricingPage,
  meta: {
    authenticated: true,
    permission: "pricing_pricing_dashboard",
    breadcrumbs: [
      { label: "Pricing" },
      { label: "Dashboard", to: "pricingDashboard", on: ["pricingDashboard", "pricingEditQuote", "pricingNewQuote"] },
      { label: "Edit Pricing Request", to: "pricingEditQuote", on: ["pricingEditQuote"] },
      { label: "New Pricing Request", to: "pricingNewQuote", on: ["pricingNewQuote"] },
    ],
  },
  children: [
    {
      path: ":companyId",
      redirect: { name: "pricingDashboard" },
    },
    {
      name: "pricingDashboard",
      path: ":companyId/dashboard",
      component: PricingDashboardPage,
      props: true,
      meta: {
        title: `Pricing Dashboard`,
      },
    },
    {
      name: "pricingNewQuote",
      path: ":companyId/quote/new",
      component: PricingEditQuote,
      props: true,
      meta: {
        title: `New Pricing Request`,
      },
    },
    {
      name: "pricingEditQuote",
      path: ":companyId/quote/:quoteId",
      component: PricingEditQuote,
      props: true,
      meta: {
        title: `Edit Pricing Request`,
      },
    },
  ],
};

export default routes;
