import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import type { Result } from "@/bapi-client/types";
import type {
  ActivityRecordResponse,
  BAPIParamsCreateQuoteNote,
  BAPIParamsUpsertQuote,
  QuoteFilters,
  QuoteNoteResponse,
  QuoteResponse,
  QuoteStatsResponse,
} from "@/bapi-client/types/pricing";

export async function getQuotes(
  customerId: string,
  filters?: QuoteFilters,
  sort_column?: string,
  sort_column_direction?: string,
  current_page?: number,
  page_size?: number,
  shipper_name?: string,
): Promise<Result<{ quotes: QuoteResponse[]; total: number }>> {
  let path = `/pricing/${customerId}/quotes`;

  const queryParams = new URLSearchParams();

  if (sort_column) queryParams.append("sort_column", sort_column);
  if (sort_column_direction) queryParams.append("sort_column_direction", sort_column_direction);
  if (current_page) queryParams.append("current_page", current_page.toString());
  if (page_size) queryParams.append("page_size", page_size.toString());
  if (shipper_name) queryParams.append("shipper_name", shipper_name);

  for (const filter in filters) {
    if (filters[filter] !== "" && filters[filter] !== null) {
      queryParams.append(filter, filters[filter].toString());
    }
  }

  path += `?${queryParams.toString()}`;

  const response = await fetchHandler(BAPI_COMMANDS.PRICING_GET_QUOTES, "GET", path);
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: {
      quotes: parsed.data.quotes,
      total: parsed.data.total_record_count,
    },
  };
}

export async function getQuote(customerId: string, quoteId: string): Promise<Result<QuoteResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_GET_QUOTE,
    "GET",
    `/pricing/${customerId}/quotes/${quoteId}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.quote,
  };
}

export async function createQuote(customerId: string, payload: BAPIParamsUpsertQuote): Promise<Result<QuoteResponse>> {
  const response = await fetchHandler(BAPI_COMMANDS.PRICING_CREATE_QUOTE, "POST", `/pricing/${customerId}/quotes`, {
    body: payload,
  });

  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.quote,
  };
}

export async function updateQuote(
  customerId: string,
  quoteId: string,
  payload: BAPIParamsUpsertQuote,
): Promise<Result<QuoteResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_UPDATE_QUOTE,
    "PUT",
    `/pricing/${customerId}/quotes/${quoteId}`,
    {
      body: payload,
    },
  );

  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.quote,
  };
}

export async function deleteQuote(customerId: string, quoteId: string): Promise<Result<QuoteResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_DELETE_QUOTE,
    "DELETE",
    `/pricing/${customerId}/quotes/${quoteId}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.quote,
  };
}

export async function copyQuote(customerId: string, quoteId: string): Promise<Result<QuoteResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_COPY_QUOTE,
    "POST",
    `/pricing/${customerId}/quotes/${quoteId}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.quote,
  };
}

export async function getNotes(customerId: string, quoteId: string): Promise<Result<QuoteNoteResponse[]>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_GET_NOTES,
    "GET",
    `/pricing/${customerId}/quotes/${quoteId}/notes`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.notes,
  };
}

export async function createNote(
  customerId: string,
  quoteId: string,
  payload: BAPIParamsCreateQuoteNote,
): Promise<Result<QuoteNoteResponse[]>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_CREATE_NOTE,
    "POST",
    `/pricing/${customerId}/quotes/${quoteId}/notes`,
    {
      body: payload,
    },
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.notes,
  };
}

export async function deleteNote(
  customerId: string,
  quoteId: string,
  noteId: string,
): Promise<Result<{ success: boolean }>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_DELETE_NOTE,
    "DELETE",
    `/pricing/${customerId}/quotes/${quoteId}/notes/${noteId}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data,
  };
}

export async function getUploadUrl(customerId: string): Promise<Result<{ attachment_id: string; upload_url: string }>> {
  const response = await fetchHandler(BAPI_COMMANDS.PRICING_GET_UPLOAD_URL, "GET", `/attachments/${customerId}/upload`);
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  const { success, ...restData } = parsed.data;

  return {
    success: true,
    data: restData,
  };
}

export async function getActivityFeed(customerId: string, quoteId: string): Promise<Result<ActivityRecordResponse[]>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_GET_ACTIVITY_FEED,
    "GET",
    `/pricing/${customerId}/quotes/${quoteId}/feed`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: parsed.data.activity_feed,
  };
}

export async function getPricingHistoryReport(
  customerId: string,
  fileType: string,
  filters?: QuoteFilters,
): Promise<Result<{ file: Blob; headers: Headers }>> {
  let path = `/pricing/${customerId}/quotes/${fileType === "csv" ? "csv" : "xlsx"}`;

  const queryParams = new URLSearchParams();
  for (const filter in filters) {
    if (filters[filter]) {
      queryParams.append(filter, filters[filter].toString());
    }
  }

  path += `?${queryParams.toString()}`;

  const response = await fetchHandler(BAPI_COMMANDS.PRICING_GET_PRICING_HISTORY_REPORT, "GET", path);
  if (!response.success) return response;

  const parsed = await response.data.blob();
  if (!parsed.success) return parsed;

  const raw = response.data.raw();

  return {
    success: true,
    data: {
      file: parsed.data,
      headers: raw.headers,
    },
  };
}

export async function getPricingQuoteStats(customerId: string): Promise<Result<QuoteStatsResponse>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.PRICING_GET_QUOTE_STATS,
    "GET",
    `/pricing/${customerId}/quotes/stats`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  const { success, ...restData } = parsed.data;

  return {
    success: true,
    data: restData,
  };
}
