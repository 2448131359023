import { reactive } from "vue";
import { defineStore } from "pinia";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

const useFeatureStore = defineStore("features", () => {
  const features = reactive({
    hasInternalToolsModule: useLDFlag("has-internal-tools-module"),
    hasInteledeskForm: useLDFlag("has-inteledesk-form"),
    hasWaybillModule: useLDFlag("has-waybill-module"),
    hasInvoicingModule: useLDFlag("invoicing-module"),
    hasPlatformNotifications: useLDFlag("notifications"),
    hasPayerInvoiceSettings: useLDFlag("has-payer-invoice-settings"),
  });

  return {
    features,
  };
});

export default useFeatureStore;
