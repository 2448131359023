import type { RouteRecordRaw } from "vue-router";

const SettingsPage = () => import("@/pages/settings/SettingsPage.vue");
const CompanyProfilesPage = () => import("@/pages/settings/CompanyProfilesPage.vue");
const CompanyProfilePage = () => import("@/pages/settings/CompanyProfilePage.vue");

export const settingsRoutes: RouteRecordRaw = {
  name: "settingsPage",
  component: SettingsPage,
  path: "/settings/:customerId",
  meta: {
    authenticated: true,
    permission: "company_settings",
  },
  props: true,
  children: [
    {
      name: "companyProfilesPage",
      path: "companies",
      component: CompanyProfilesPage,
      props: true,
      children: [{ name: "companyProfilePage", path: ":payerCompanyId", component: CompanyProfilePage, props: true }],
    },
  ],
};
