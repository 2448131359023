interface OktaConfig {
  issuer: string;
  clientId: string;
  redirectUri: string;
  scopes: string[];
}

interface DomainConfig {
  logoutUrl: string;
  oktaAuth?: OktaConfig;
  supportMsg?: string;
}

interface DomainMapping {
  [domain: string]: DomainConfig;
}

/**
 * Maps domains to their corresponding configurations
 */
export const domainMappings: DomainMapping = {
  "rvpviz.railconnect.com": {
    logoutUrl: "https://my.railconnect.com/product-hub",
    oktaAuth: {
      issuer: "https://wabtec.okta.com/oauth2/default",
      clientId: "0oa6v67je5Vw9P1J0357",
      redirectUri: window.location.origin + "/auth/callback",
      scopes: ["openid", "profile", "email"],
    },
    supportMsg: "Unauthorized user, please contact Wabtec Support.",
  },
  "rvpvizstg.railconnect.com": {
    logoutUrl: "https://mystg.railconnect.com/product-hub",
    oktaAuth: {
      issuer: "https://wabtec.okta.com/oauth2/default",
      clientId: "0oa6v67je5Vw9P1J0357",
      redirectUri: window.location.origin + "/auth/callback",
      scopes: ["openid", "profile", "email"],
    },
    supportMsg: "Unauthorized user, please contact Wabtec Support.",
  },
};

/**
 * Default fallback URL if no specific mapping is found
 */
export const defaultLogoutUrl = "/auth/logout";

/**
 * Gets the appropriate logout URL for the given domain
 * @param domain The current domain
 * @returns The logout URL for the given domain or the default URL if no mapping exists
 */
export function getLogoutUrl(domain: string): string {
  return domainMappings[domain]?.logoutUrl || defaultLogoutUrl;
}

/**
 * Gets the Okta authentication configuration for the given domain
 * @param domain The current domain
 * @returns The Okta authentication configuration for the given domain or undefined if no mapping exists
 */
export function getOktaConfig(domain: string): OktaConfig | undefined {
  return domainMappings[domain]?.oktaAuth;
}

/**
 * Gets the support message for the given domain
 * @param domain The current domain
 * @returns The support message or undefined if no mapping exists
 */
export function getSupportMsg(domain: string): string | undefined {
  return domainMappings[domain]?.supportMsg;
}

/**
 * Clears Okta token storage from localStorage if it exists
 * @returns True if Okta token was cleared, false otherwise
 */
export function clearOktaTokenStorage(): boolean {
  const oktaTokenStorage = localStorage.getItem("okta-token-storage");
  if (oktaTokenStorage && Object.keys(JSON.parse(oktaTokenStorage)).length) {
    localStorage.removeItem("okta-token-storage");
    return true;
  }
  return false;
}
