import type { PipelineReportAsset, PipelineReportParams } from "../types/assets/pipelineAsset";
import { fetchHandler } from "../fetch";
import { Result } from "../types";

export async function getInboundPipeline(
  customerId: string,
  filters: PipelineReportParams,
): Promise<Result<{ success: boolean; record_count: number; data: PipelineReportAsset[] }>> {
  const body: PipelineReportParams = {
    destination: filters.destination ?? "",
    group_ids: filters.group_ids ?? [],
    max_age: filters.max_age ?? "2",
  };

  const res = await fetchHandler("getPipelineReport", "POST", `/reports/${customerId}/inbound_pipeline`, {
    body,
    canAbort: true,
  });

  if (!res.success) {
    return res;
  }

  const parsed = await res.data.json();
  return parsed.success ? { success: true, data: parsed.data } : parsed;
}
