import { BAPI_COMMANDS } from "../../types/commands";
import type { Result } from "../../types";
import { fetchHandler } from "@/bapi-client/fetch";
import { userFactory } from "@/bapi-client/factories/users";
import type { User } from "@/bapi-client/types/users";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function loginOAuthUser(credentials: any): Promise<Result<User>> {
  const response = await fetchHandler(BAPI_COMMANDS.USER_OAUTH_LOGIN, "POST", "/user/login/wabtec/token", {
    body: credentials,
  });
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: userFactory(parsed.data),
  };
}
