import { ref } from "vue";
import { defineStore } from "pinia";
import useNotificationStore from "./notifications";
import { useFetch } from "@/composables/fetch";
import { Quote, makeQuote } from "@/models/pricing/quotes";
import useTrackerStore from "./trackers";
import { RUDDERSTACK_EVENTS, RudderstackEvent } from "@/lib/rudderstack";

export const useShipperStore = defineStore("shipper", () => {
  const notifier = useNotificationStore();
  const trackers = useTrackerStore();
  const receivedQuote = ref<Quote>();
  const companyId = ref<string>("");
  const quoteId = ref<string>("");
  const token = ref<string>("");
  const quoteReviewNote = ref<string>("");

  async function getQuote() {
    notifier.setLoading("Loading");
    const request = useFetch();
    notifier.setLoading();
    const response = await request.get(`/shippers/quotes/${quoteId.value}?customer_id=${companyId.value}`, {
      headers: { "X-Auth-Token": token.value },
    });

    if (!response.ok) {
      notifier.setToast("danger", "We were unable to load the requested quote at this time.");
      console.error("Unable to GET shipper quote: ", response.status);
      return undefined;
    }

    const data = await response.json();
    receivedQuote.value = makeQuote(data.quote);
  }

  async function submitQuoteResponse(status: number) {
    notifier.setLoading("Loading");
    const body = {
      data: { quote_review_note: quoteReviewNote.value, updated_by: "shipper" },
      status,
    };
    const rsData = {
      shipper_name: receivedQuote.value?.shipper.name,
      quote_id: receivedQuote.value?.id,
      success: false,
    };
    let rsEvent: RudderstackEvent = RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_ACCEPT;
    if (status !== 50) {
      rsEvent =
        receivedQuote.value?.rejectedOnce === false
          ? RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_SEND_COUNTER
          : RUDDERSTACK_EVENTS.PRICING_QUOTE_SHIPPER_SEND_REJECTION;
    }
    const request = useFetch();
    const response = await request.post(`/shippers/quotes/${quoteId.value}?customer_id=${companyId.value}`, {
      headers: { "X-Auth-Token": token.value },
      body,
    });

    notifier.setLoading();

    if (!response.ok) {
      notifier.setToast("danger", "We were unable to send the response at this time.");
      console.error("Unable to POST shipper quote: ", response.status);
      trackers.logRudderstackEvent(rsEvent, rsData);
      return;
    }
    if (quoteReviewNote.value.length) {
      const noteResponse = await saveNote(quoteReviewNote.value, false);
      if (!noteResponse) {
        notifier.setToast("danger", "Request note not saved");
      }
    }
    notifier.setToast("success", "Response was sent successfully.");
    rsData.success = true;
    trackers.logRudderstackEvent(rsEvent, rsData);
  }

  async function saveNote(note: string, isInternal: false) {
    const request = useFetch();
    const response = await request.post(`/shippers/quotes/${quoteId.value}/notes?customer_id=${companyId.value}`, {
      headers: { "X-Auth-Token": token.value },
      body: { note, is_internal: isInternal },
    });
    if (!response.ok) {
      return undefined;
    }
    return response.ok;
  }

  return {
    getQuote,
    receivedQuote,
    submitQuoteResponse,
    companyId,
    quoteId,
    token,
    quoteReviewNote,
  };
});
