<!-- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog -->
<script setup lang="ts">
import { ref, watch, onMounted } from "vue";

interface Props {
  isActive?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{ (event: "dismiss"): void }>();

const dialog = ref<HTMLDialogElement | null>(null);

function openModal() {
  // native dialog API method
  dialog.value?.showModal();
}

function closeModal() {
  // native dialog API method
  dialog.value?.close();
  // can hook into this optionally if you need to do something when the dialog closes
  emit("dismiss");
}

// watch here calls the native open and close methods
watch(
  () => props.isActive,
  (next) => {
    if (next === true) {
      return openModal();
    }
    closeModal();
  },
);

onMounted(() => {
  if (props.isActive) {
    openModal();
  }
  // this event listener is necessary to close the dialog when the backdrop is clicked
  dialog.value?.addEventListener("click", () => {
    if (!props.isActive) {
      return dialog.value?.close();
    }
    emit("dismiss");
  });
  // this event listener is necessary to send dismiss event the dialog when _any_ button is clicked on inside of the form -> slot content, the event will by default close the dialog
  dialog.value?.addEventListener("close", () => {
    emit("dismiss");
  });
});
</script>

<template>
  <div>
    <slot name="trigger" :open="openModal" />
    <dialog
      ref="dialog"
      class="h-screen max-h-screen w-screen flex-col items-center overflow-auto bg-transparent pt-48 backdrop:bg-gray-900/80 open:flex open:animate-fadein"
    >
      <div class="fixed right-0 top-0 hidden pr-4 pt-4 sm:block">
        <button
          data-testid="modal-close"
          class="focus:ring-indigo-500 flex h-10 w-10 flex-col items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
          aria-label="close"
          @click.stop="closeModal"
        >
          <span class="sr-only">Close</span>
          <i class="fa-solid fa-xmark" aria-hidden="true" />
        </button>
      </div>
      <!-- IMPORTANT: If you desire a button in the slot content to do something other than close the modal, you must add the .prevent modifier so that the native dialog API doesn't call its `close` event -->
      <form method="dialog" data-testid="modal-content" @click.stop>
        <slot :close="closeModal" />
      </form>
    </dialog>
  </div>
</template>
