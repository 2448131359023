import { createRouter, RouteRecordRaw, createWebHistory, RouteLocationNormalized } from "vue-router";
import useFeatureStore from "./stores/features";
import { useUserStore } from "@/stores/user";
import { loginRoutes } from "./routes/login";
import { telematicsRoutes } from "./routes/telematics";
import waybillingRoutes from "./routes/waybilling";
import { reportBuilderRoutes } from "./routes/report-builder";
import visibilityRoutes from "./routes/visibility";
import analyticsRoutes from "./routes/analytics";
import reportsRoutes from "./routes/reports";
import pricingRoutes from "./routes/pricing";
import shipperRoutes from "./routes/shipper";
import adminRoutes from "./routes/admin";
import customerSupportRoutes from "./routes/customer-support";
import { invoicingRoutes } from "./routes/invoicing";
import { settingsRoutes } from "./routes/settings";
import { useTheme } from "./composables/useTheme";
import { oAuthLoginRoutes } from "./routes/callback";
import { getOktaConfig } from "./utils/domainMappings";
import App404Error from "./components/errors/App404Error.vue";

const AppView = () => import("@/pages/AppPage.vue");
const DashboardPage = () => import("@/pages/DashboardPage.vue");
const TerminalWrapper = () => import("@/components/terminal/TerminalPage.vue");
const TerminalLoadList = () => import("@/components/terminal/TerminalLoadList.vue");
const TerminalLogin = () => import("@/components/terminal/TerminalLogin.vue");
const UserProfilePage = () => import("@/pages/userProfile/UserProfile.vue");

const { setTitle } = useTheme();
const routes: RouteRecordRaw[] = [
  {
    name: "app",
    path: "/",
    component: AppView,
    children: [
      {
        name: "dashboard",
        path: "/",
        component: DashboardPage,
        meta: {
          authenticated: true,
          permissions: [
            "dashboard_widgets_dwelling_over_2_days",
            "dashboard_widgets_ap_status",
            "dashboard_widgets_cp_status_or_notified",
            "dashboard_widgets_ready_for_release",
          ],
          title: `Dashboard`,
        },
      },
      visibilityRoutes,
      telematicsRoutes,
      analyticsRoutes,
      reportsRoutes,
      reportBuilderRoutes,
      pricingRoutes,
      invoicingRoutes,
      settingsRoutes,
      waybillingRoutes,
      adminRoutes,
      shipperRoutes,
      customerSupportRoutes,
      {
        name: "userProfile",
        path: "/profile",
        component: UserProfilePage,
        meta: { authenticated: true, title: `My Profile` },
        beforeEnter: (to, from, next) => {
          const userStore = useUserStore();

          if (!userStore.user) {
            next({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
    ],
  },
  loginRoutes,
  oAuthLoginRoutes,
  {
    name: "terminal",
    path: "/terminal",
    component: TerminalWrapper,
    meta: {
      permission: "terminal_terminal",
    },
    children: [
      {
        name: "terminalLogin",
        path: "login",
        component: TerminalLogin,
        meta: {
          authenticated: false,
          title: `Terminal Login`,
        },
      },
      {
        name: "terminalLoadList",
        path: "loads",
        alias: "/terminal",
        component: TerminalLoadList,
        meta: { title: `Terminal View`, authenticated: true },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: App404Error },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

async function checkUser(to: RouteLocationNormalized) {
  const userStore = useUserStore();

  if (!userStore.isUserVerified) {
    userStore.initialToPath = to.fullPath;
    userStore.initialQueryParams = to.query;
  }

  return await userStore.verifyCurrentUser();
}

router.beforeEach(async (to) => {
  if (to.meta?.title) {
    setTitle(`${to.meta.title}`);
  }
  if (!to.meta?.authenticated) {
    return;
  }

  // Coming in on a new tab, etc.
  if (to.meta?.authenticated) {
    const verifiedUser = await checkUser(to);
    if (!verifiedUser) {
      // Get the current domain
      const currentDomain = window.location.hostname;
      // Check if this domain has Okta configured
      const hasOktaConfig = !!getOktaConfig(currentDomain);
      if (hasOktaConfig) {
        // Send them to the OAuth failed login page
        return { name: "oauthLoginFailed" };
      }
      return to.path.startsWith("/terminal") ? { name: "terminalLogin" } : { name: "login" };
    }
  }

  if (to.params.companyId) {
    const userStore = useUserStore();
    if (to.params.companyId !== userStore.companyId) {
      // must to string this here b/c the param is type string | string[] and selectCompany takes a string
      await userStore.setUserCustomer(to.params.companyId as string);
    }
  }

  if (to.name !== "login" && to.name !== "terminalLogin" && !to.path.startsWith("/terminal")) {
    const userStore = useUserStore();
    if (userStore.company?.roleId === 7 || userStore.company?.roleId === 8) {
      return { name: "terminalLoadList" };
    }
  }

  if (to.meta?.permissions) {
    const userStore = useUserStore();
    if (!(to.meta.permissions as Array<string>).some((p) => userStore.permissions.includes(p))) {
      return userStore.landingPage;
    }
  } else if (to.meta?.permission) {
    const userStore = useUserStore();
    if (!userStore.permissions.includes(to.meta.permission as string)) {
      return userStore.landingPage;
    }
  } else if (to.meta?.flag) {
    const featureStore = useFeatureStore();
    const userStore = useUserStore();
    // if you don't have this feature, go to your landing page.
    if (!featureStore.features[to.meta.flag as keyof typeof featureStore.features]) {
      return userStore.landingPage;
    }
  }
});

router.onError((e) => {
  console.error("ERROR IN ROUTER: ", e);
  console.error(router.currentRoute);
});
export default router;
