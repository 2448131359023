import { RouteRecordRaw } from "vue-router";

const TelematicsPage = () => import("@/pages/telematics/TelematicsPage.vue");
const WatchtowerPage = () => import("@/pages/telematics/WatchtowerPage.vue");

export const telematicsRoutes: RouteRecordRaw = {
  name: "telematicsPage",
  component: TelematicsPage,
  path: "/telematics/:companyId/",
  meta: {
    authenticated: true,
    permission: "telematics_watchtower",
    breadcrumbs: [{ label: "Telematics" }, { label: "Watchtower", to: "watchtower", on: ["watchtower"] }],
  },
  children: [
    {
      name: "watchtower",
      component: WatchtowerPage,
      path: "",
      meta: {
        title: `Watchtower`,
      },
    },
  ],
};
