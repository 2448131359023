import { useRouteQuery } from "@vueuse/router";
import { reactive } from "vue";

export function usePaged(pageNumber = 1, pageSize = 25, direction: "ASC" | "DESC" = "DESC", sortColumn?: string) {
  const page = reactive({
    current: useRouteQuery("page", pageNumber, { transform: Number }),
    size: useRouteQuery("page_size", pageSize, { transform: Number }),
    total: 0,
  });

  const total = reactive({
    pages: 0,
    records: 0,
  });

  const sort = reactive({
    column: useRouteQuery<string>("sort", sortColumn),
    direction: useRouteQuery<"ASC" | "DESC">("direction", direction),
  });

  return { page, total, sort };
}
