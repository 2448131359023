import { reactive, toRef, Ref } from "vue";
import { v4 as uuid } from "uuid";
import { bfs, FormKitNode, register } from "@formkit/core";
import type { CIFPartyItem, PatternInputDetails } from "@telegraphio/papi-client";

type StepData = {
  valid: Ref<boolean>;
  submitted: Ref<boolean>;
};

const tenderRequiredFields = [
  "extended_reference_information_N9.reference_identification_qualifier_01",
  "extended_reference_information_N9.reference_identification_02",
  "party_identification_N1_loop.party_identification_N1.entity_identifier_code_01",
  "equipment_details_N7_loop.equipment_details_N7.equipment_initial_01",
  "equipment_details_N7_loop.equipment_details_N7.equipment_number_02",
  "equipment_details_N7_loop.equipment_details_N7.weight_03",
  "equipment_details_N7_loop.equipment_details_N7.weight_qualifier_04",
];

export default function useFormPlugins(inputDetails: Ref<PatternInputDetails[]>, isPattern: boolean) {
  const steps = reactive<Record<string, StepData>>({});

  const buildPath = (node: FormKitNode) => {
    let path = node.name;
    let parent = node.parent;
    let previousName = null;

    while (parent) {
      // Top level segments are wrapped by a group with the same name. So if the name matches
      // the previous name, we know we've reached the top.
      if (parent.name === previousName) {
        break;
      }

      let name = parent.name;

      if (Number.isInteger(parseInt(name))) {
        name = `[${name}]`;
      }

      path = `${name}.${path}`;
      previousName = parent.name;
      parent = parent.parent;
    }

    return path;
  };

  const stepPlugin = (node: FormKitNode) => {
    if (node.props.type === "group") {
      // builds an object of the top-level groups
      steps[node.name] = steps[node.name] || { seen: false };

      node.on("created", () => {
        // use 'on created' to ensure context object is available
        if (node.context) {
          steps[node.name].valid = toRef(node.context.state, "valid");
          steps[node.name].submitted = toRef(node.context.state, "submitted");
        }
      });

      // Stop plugin inheritance to descendant nodes
      return false;
    }
  };

  const inputPlugin = (node: FormKitNode) => {
    const excludeTypes = ["form", "group", "repeater"];

    if (!excludeTypes.includes(node.props.type)) {
      const path = buildPath(node);

      const nodeId = uuid();

      node.props.id = nodeId;

      const isVariableCheckbox = node.name.endsWith("-checkbox");

      const truePath = isVariableCheckbox ? path.split("-")[0] : path;
      const ediPath = path.replace(/\.\[\d+\]/g, "");

      const details = inputDetails.value.find((detail) => detail.target === truePath);

      if (tenderRequiredFields.includes(ediPath) && !isPattern) {
        node.props.validation = `required|${node.props.validation}`;
      }

      if (isVariableCheckbox) {
        // We don't want the checkbox values in the output, so we intercept
        // the value and return undefined instead.
        node.hook.input((_, next) => {
          return next(undefined);
        });

        node.on("mounted", () => {
          const el = document.getElementById(nodeId) as HTMLInputElement;
          if (details?.optional === false) {
            el.checked = true;
          }
        });
      } else if (details) {
        if (details.name !== node.name) {
          node.props.attrs.originalLabel = node.props.label;
          node.props.label = details.name;
          node.props.labelClass = "text-blue-400";
          node.props.attrs.optionLabel = details.name;
        }

        if (details.optional === false) {
          node.props.attrs.optionRequired = true;
        }

        if (!details.optional && !isPattern && !tenderRequiredFields.includes(ediPath)) {
          node.props.validation = `required|${node.props.validation}`;
        }
      }

      if (node.props.type === "date") {
        node.hook.commit((value, next) => {
          if (!value) return;

          if (!value.includes("-")) {
            return next(`${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6, 8)}`);
          }

          return next(value);
        });
      }

      if (node.props.type === "time") {
        node.hook.commit((value, next) => {
          if (!value) return;

          if (!value.includes(":")) {
            return next(`${value.slice(0, 2)}:${value.slice(2, 4)}`);
          }

          return next(value);
        });
      }

      if (node.name === "standard_carrier_alpha_code_01") {
        node.on("mounted", () => {
          const parent = node.parent as FormKitNode;
          const cityNode = parent.children.find((child) => child.name === "city_name_03");

          if (cityNode && !node.value) {
            cityNode.props.disabled = true;
          }
        });

        node.on("commit", () => {
          const parent = node.parent as FormKitNode;
          const cityNode = parent.children.find((child) => child.name === "city_name_03");

          if (cityNode && node.value) {
            cityNode.props.disabled = false;
          } else if (cityNode) {
            cityNode.input(undefined);
            cityNode.props.disabled = true;
          }
        });
      }

      register(node);
    }

    return true;
  };

  const locationPlugin = (node: FormKitNode) => {
    const lookupNodes = ["origin-lookup", "destination-lookup", "disposition-lookup"];

    if (node.props.type === "autocomplete" && lookupNodes.includes(node.name)) {
      const parent = node.parent as FormKitNode;

      node.on("created", () => {
        let city = null;
        let state = null;
        let country = null;

        if (node.name === "disposition-lookup") {
          city = bfs(parent, "city_name_01", "name")?.value;
          state = bfs(parent, "state_or_province_code_02", "name")?.value;
          country = bfs(parent, "country_code_04", "name")?.value;
        } else {
          city = bfs(parent, "city_name_02", "name")?.value;
          state = bfs(parent, "state_or_province_code_03", "name")?.value;
          country = bfs(parent, "country_code_04", "name")?.value;
        }

        if (!city && !state && !country) {
          return;
        }

        const location = `${city || ""}, ${state || ""}, ${country || ""}`;

        node.input(location);
      });

      node.on("commit", () => {
        if (!node.value) {
          if (node.name === "disposition-lookup") {
            bfs(parent, "city_name_01", "name")?.input(undefined);
            bfs(parent, "state_or_province_code_02", "name")?.input(undefined);
            bfs(parent, "country_code_04", "name")?.input(undefined);
          } else {
            bfs(parent, "city_name_02", "name")?.input(undefined);
            bfs(parent, "state_or_province_code_03", "name")?.input(undefined);
            bfs(parent, "country_code_04", "name")?.input(undefined);
          }
          return;
        }

        if (typeof node.value !== "string") return;

        const [city, state, country] = node.value.split(", ");

        if (node.name === "disposition-lookup") {
          bfs(parent, "city_name_01", "name")?.input(city);
          bfs(parent, "state_or_province_code_02", "name")?.input(state);
          bfs(parent, "country_code_04", "name")?.input(country);
        } else {
          bfs(parent, "city_name_02", "name")?.input(city);
          bfs(parent, "state_or_province_code_03", "name")?.input(state);
          bfs(parent, "country_code_04", "name")?.input(country);
        }
      });
    }
  };

  const commodityPlugin = (node: FormKitNode) => {
    if (node.name === "commodity-lookup") {
      node.hook.commit((value, next) => {
        if (!value) {
          return;
        }

        const parent = node.parent as FormKitNode;
        const [code, description] = value.split(" - ");

        const maxLength = 50;
        const truncatedDescription = description.length > maxLength ? description.slice(0, maxLength) : description;

        bfs(parent, "lading_description_02", "name")?.input(truncatedDescription);
        bfs(parent, "commodity_code_03", "name")?.input(code);

        next(undefined);
      });
    }
  };

  const partyPlugin = (node: FormKitNode) => {
    if (node.name === "party-lookup") {
      node.hook.commit((value: CIFPartyItem, next) => {
        if (!value) {
          return;
        }

        const parent = node.parent as FormKitNode;

        let state = value.state;
        if (value.state === "NL" && value.country) {
          state = `${value.country}-${value.state}`;
        }

        bfs(parent, "identification_code_qualifier_03", "name")?.input("C5");
        bfs(parent, "name_02", "name")?.input(value.customerName);
        bfs(parent, "identification_code_04", "name")?.input(value.customerId);
        bfs(parent, "address_information_01", "name")?.input(value.address1);
        bfs(parent, "address_information_02", "name")?.input(value.address2);
        bfs(parent, "city_name_01", "name")?.input(value.city);
        bfs(parent, "state_or_province_code_02", "name")?.input(state);
        bfs(parent, "postal_code_03", "name")?.input(value.postal);
        bfs(parent, "country_code_04", "name")?.input(value.country);

        next(undefined);
      });
    }

    if (node.props.attrs.ediPath === "geographic_location_N4.state_or_province_code_02") {
      node.on("mounted", () => {
        if (node.value === "NL") {
          const parent = node.parent?.parent as FormKitNode;
          const country = bfs(parent, "country_code_04", "name");

          if (country?.value) {
            node.input(`${country.value}-${node.value}`);
          }
        }
      });

      node.hook.commit((value: string, next) => {
        if (!value) {
          return;
        }

        let nextValue = value;

        if (value.includes("-")) {
          nextValue = value.split("-")[1];
        }

        return next(nextValue);
      });
    }
  };

  const validationPlugin = (node: FormKitNode) => {
    if (!isPattern && node.props.attrs.dependentOn) {
      node.on("mounted", () => {
        const parent = node.parent?.parent as FormKitNode;
        const dependant = bfs(parent, node.props.attrs.dependentOn, "name");

        if (!node.value && dependant?.value) {
          node.props.validation = `required|${node.props.validation}`;
        }
      });

      node.on("commit", () => {
        const parent = node.parent?.parent as FormKitNode;
        const dependant = bfs(parent, node.props.attrs.dependentOn, "name");

        if (!dependant) return;

        const validation = dependant.props.validation || "";

        if (node.value && !validation.includes("required")) {
          dependant.props.validation = `required|${validation}`;
        } else if (!node.value) {
          dependant.props.validation = validation.replace("required|", "");
        }
      });
    }

    if (
      !isPattern &&
      node.props.type === "repeater" &&
      node.name === "empty_car_disposition_pended_destination_consignee_E1_loop"
    ) {
      node.on("mounted", () => {
        node.children.forEach((child) => {
          const childNode = child as FormKitNode;
          const consignee = bfs(childNode, "name_01", "name") as FormKitNode;

          const childValue = childNode.value as Record<string, unknown>;

          const isDestinationEmpty = Object.values(
            childValue.empty_car_disposition_pended_destination_city_E4 as Record<string, string>,
          ).every((value) => value === undefined);

          const isPriceAuthorityEmpty = Object.values(
            childValue.price_authority_identification_PI as Record<string, string>,
          ).every((value) => value === undefined);

          const isEmpty = isDestinationEmpty && isPriceAuthorityEmpty;

          if (!isEmpty) consignee.props.validation = `required|${consignee.props.validation}`;

          childNode.on("commit", () => {
            const childValue = childNode.value as Record<string, unknown>;

            const isDestinationEmpty = Object.values(
              childValue.empty_car_disposition_pended_destination_city_E4 as Record<string, string>,
            ).every((value) => !value);

            const isPriceAuthorityEmpty = Object.values(
              childValue.price_authority_identification_PI as Record<string, string>,
            ).every((value) => !value);

            const isEmpty = isDestinationEmpty && isPriceAuthorityEmpty;

            if (isEmpty) {
              consignee.props.validation = consignee.props.validation.replace("required|", "");
            } else if (!consignee.props.validation.includes("required")) {
              consignee.props.validation = `required|${consignee.props.validation}`;
            }
          });
        });
      });
    }
  };

  return {
    steps,
    buildPath,
    stepPlugin,
    inputPlugin,
    locationPlugin,
    commodityPlugin,
    partyPlugin,
    validationPlugin,
  };
}
