<script setup lang="ts">
import { computed } from "vue";
import { FormKit } from "@formkit/vue";
import { TgButton } from ".";

interface Props {
  lastPage: number;
  total: number;
  withTotals?: boolean;
  withPageSize?: boolean;
  withDownload?: boolean;
  pageSizeOptions?: number[];
  withRefresh?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  withTotals: true,
  withPageSize: false,
  pageSizeOptions: () => [25, 50, 100],
});

const currentPage = defineModel<number>("currentPage", {
  default: 1,
});

const pageSize = defineModel<number>("pageSize", {
  default: 25,
  set(value: number) {
    currentPage.value = 1;
    return value;
  },
});

defineEmits<{ (event: "refresh-table"): void; (event: "download"): void }>();

const fromCount = computed(() => {
  if (props.total === 0) {
    return 0;
  }
  return (currentPage.value - 1) * pageSize.value + 1;
});
const toCount = computed(() => {
  if (currentPage.value * pageSize.value > props.total) {
    return props.total;
  }
  return currentPage.value * pageSize.value;
});

function goToPage(num: number): void {
  if (num < 1 || num > props.lastPage) {
    return undefined;
  }
  currentPage.value = num;
}
</script>

<template>
  <div class="flex w-full shrink-0 items-center justify-between">
    <div class="flex items-center justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 sm:hidden"
        data-testid="pagination-previous-page"
        @click.prevent="goToPage(currentPage - 1)"
        >Previous</a
      >
      <a
        href="#"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 sm:hidden"
        data-testid="pagination-next-page"
        @click.prevent="goToPage(currentPage + 1)"
        >Next</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div class="flex items-center gap-6">
        <p v-if="withTotals" class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{ fromCount }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ toCount }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ total }}</span>
          {{ " " }}
          results
        </p>
        <div v-if="withPageSize" class="relative flex items-center gap-2">
          <FormKit v-model.number="pageSize" type="select" :options="pageSizeOptions" :classes="{ icon: 'pl-3' }" />
          <p class="text-sm text-gray-700">results per page</p>
        </div>
        <div v-if="withRefresh" class="cursor-pointer" @click="$emit('refresh-table')">
          <p class="text-sm text-blue"><i class="fa-solid fa-rotate-right" /> Refresh Table</p>
        </div>
      </div>
      <TgButton
        v-if="withDownload"
        leading-icon="download"
        color="white"
        class="ml-auto mr-6"
        @click="$emit('download')"
      >
        CSV
      </TgButton>
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <a
          href="#"
          class="relative inline-flex items-center justify-center rounded-l-md px-6 py-3 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          data-testid="pagination-first-page"
          @click.prevent="goToPage(1)"
        >
          <span class="sr-only">First page</span>
          <i class="fa-solid fa-chevrons-left h-4 w-4" aria-hidden="true" />
        </a>
        <a
          href="#"
          class="relative inline-flex items-center px-6 py-3 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          data-testid="pagination-previous-page"
          @click.prevent="goToPage(currentPage - 1)"
        >
          <span class="sr-only">Previous</span>
          <i class="fa-solid fa-chevron-left h-4 w-4" aria-hidden="true" />
        </a>
        <a
          href="#"
          :data-testid="`pagination-page-${currentPage > 2 ? currentPage - 1 : 1}`"
          class="relative inline-flex w-10 items-center justify-center p-3 text-center text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          :class="[currentPage === 1 ? 'bg-blue font-bold text-blue-sky hover:bg-blue-500/80' : 'bg-white']"
          @click.prevent="goToPage(currentPage > 2 ? currentPage - 1 : 1)"
        >
          {{ currentPage > 2 ? currentPage - 1 : 1 }}
        </a>
        <a
          v-if="lastPage > 1"
          href="#"
          :data-testid="`pagination-page-${currentPage > 2 ? currentPage : 2}`"
          class="relative inline-flex w-10 items-center justify-center p-3 text-center text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          :class="[currentPage > 1 ? 'bg-blue font-bold text-blue-sky hover:bg-blue-500/80' : 'bg-white']"
          @click.prevent="goToPage(currentPage === 1 ? 2 : currentPage)"
        >
          {{ currentPage > 2 ? currentPage : 2 }}
        </a>
        <a
          v-if="lastPage > 2 && lastPage - currentPage > 1"
          href="#"
          class="pointer-events-none relative inline-flex w-10 items-center justify-center p-3 text-center text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span><i class="fa-solid fa-ellipsis"></i></span>
        </a>
        <a
          v-if="lastPage > 2 && currentPage < lastPage"
          href="#"
          :data-testid="`pagination-page-${lastPage}`"
          class="relative inline-flex w-10 items-center justify-center p-3 text-center text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          :class="[lastPage === currentPage ? 'bg-blue font-bold text-blue-sky hover:bg-blue-500/80' : 'bg-white']"
          @click.prevent="goToPage(lastPage)"
        >
          {{ lastPage }}
        </a>
        <a
          href="#"
          class="relative inline-flex items-center justify-center px-6 py-3 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          data-testid="pagination-next-page"
          :disabled="currentPage === lastPage"
          @click.prevent="goToPage(currentPage + 1)"
        >
          <span class="sr-only">Next</span>
          <i class="fa-solid fa-chevron-right h-4 w-4" aria-hidden="true" />
        </a>
        <a
          href="#"
          class="relative inline-flex items-center rounded-r-md px-6 py-3 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          data-testid="pagination-last-page"
          @click.prevent="goToPage(lastPage)"
        >
          <span class="sr-only">Last page</span>
          <i class="fa-solid fa-chevrons-right h-4 w-4" aria-hidden="true" />
        </a>
      </nav>
    </div>
  </div>
</template>
