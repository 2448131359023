<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { onBeforeMount, onErrorCaptured } from "vue";
import { useRouter } from "vue-router";
import Rudderstack from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import useNotificationStore from "@/stores/notifications";
import AppNotifications from "@/components/AppNotifications.vue";
import AppUpdateBanner from "./components/app/AppUpdateBanner.vue";
import { ldInit } from "launchdarkly-vue-client-sdk";
import useFeatureStore from "./stores/features";
import { useTheme } from "./composables/useTheme";

const [ldReady] = ldInit({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
});

useFeatureStore();

const rudderstack = new Rudderstack();
const trackers = useTrackerStore();
const router = useRouter();
const notifier = useNotificationStore();

rudderstack.init();
rudderstack.registerRouter(router);
trackers.rudderstack = rudderstack;

onBeforeMount(async () => {
  useTheme();
  await router.isReady();
  if (import.meta.env.VITE_VERSION_POLLING_INTERVAL) {
    setInterval(notifier.checkForNewerVersion, import.meta.env.VITE_VERSION_POLLING_INTERVAL);
  }
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
});

onErrorCaptured((error, component) => {
  console.error("Uncaught error from child component: ", error);
  console.log(component);
  if (import.meta.env.PROD) {
    Sentry.captureException(error);
  }
  notifier.status = "fault";
  return false;
});
</script>

<template>
  <div class="overflow-visible bg-gray-100">
    <AppUpdateBanner />
    <div id="modal-container"></div>
    <router-view v-if="ldReady" />
    <AppNotifications />
  </div>
</template>
