import { useInvoicingStore } from "@/stores/invoicing";
import type { RouteRecordRaw } from "vue-router";

const InvoicingPage = () => import("../pages/invoicing/InvoicingPage.vue");
const InvoicesPage = () => import("../pages/invoicing/InvoicesPage.vue");
const PayeeProfilePage = () => import("../pages/invoicing/PayeeProfilePage.vue");
const NewInvoicePage = () => import("../pages/invoicing/InvoiceFormPage.vue");
const EditInvoicePage = () => import("../pages/invoicing/InvoiceFormPage.vue");
const DuplicateInvoicePage = () => import("../pages/invoicing/InvoiceFormPage.vue");

export const invoicingRoutes: RouteRecordRaw = {
  name: "invoicingPage",
  component: InvoicingPage,
  path: "/invoicing/:customerId",
  meta: {
    authenticated: true,
    flag: "hasInvoicingModule",
    // permissions: ["invoicing_invoice_dashboard", "invoicing_settings"],
  },
  beforeEnter: async (to, from, next) => {
    const invoicingStore = useInvoicingStore();
    await invoicingStore.getPayee(to.params.customerId as string);
    next();
  },
  props: true,
  children: [
    {
      name: "invoicesPage",
      path: "invoices",
      component: InvoicesPage,
      props: true,
      meta: {
        // permission: "invoicing_invoice_dashboard",
        title: `Invoicing Dashboard`,
      },
    },
    {
      name: "newInvoicePage",
      path: "invoices/new",
      component: NewInvoicePage,
      props: true,
      meta: {
        title: `New Invoice`,
      },
    },
    {
      name: "duplicateInvoicePage",
      path: "invoices/new/:invoiceId",
      component: DuplicateInvoicePage,
      props: true,
      meta: {
        title: "New Invoice (duplicate)",
      },
    },
    {
      name: "editInvoicePage",
      path: "invoices/:invoiceId/edit",
      component: EditInvoicePage,
      props: true,
      meta: {
        title: `Edit Invoice`,
      },
    },
    {
      name: "payeeProfilePage",
      path: "profile",
      component: PayeeProfilePage,
      props: true,
    },
  ],
};
