<script setup lang="ts">
import { computed } from "vue";
import useNotificationStore from "../stores/notifications";
import AppLoader from "./layout/AppLoader.vue";
import AppToast from "./app/AppToast.vue";

const store = useNotificationStore();
const loaderActive = computed(() => {
  return store.status !== "fault" && store.ACTIVE_LOADERS.length > 0;
});
</script>

<template>
  <div class="fixed bottom-5 right-7 z-[999] flex w-full max-w-screen-sm flex-col items-end justify-end gap-2">
    <AppToast />
    <AppLoader v-if="loaderActive" :message="store.loaderMessage" />
  </div>
</template>
