<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import ErrorPage from "./ErrorPage.vue";
import TgButton from "@/components/common/TgButton.vue";
export default defineComponent({
  name: "App403Error",
  components: { ErrorPage, TgButton },
  setup() {
    const router = useRouter();

    function goBack(): void {
      router.go(-1);
    }

    function login(): void {
      router.push({ name: "login" });
    }

    return { goBack, login };
  },
});
</script>
<template>
  <ErrorPage>
    <template #title>Not authorized</template>
    <template #message>
      Your account does not have access to this page. If you think this is a mistake, please try logging in again or
      please let us know!
    </template>
    <template #controls>
      <TgButton class="m-4" :is-rounded="true" color="primary" @click="login">Log in</TgButton>
      <p class="mt-2 text-sm">
        <a href="#" @click.prevent="goBack">Nevermind, take me back.</a>
      </p>
    </template>
  </ErrorPage>
</template>
