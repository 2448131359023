<script setup lang="ts">
import { computed, onMounted, provide, ref, unref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { TenderAcknowledgement, TenderAdvice } from "@telegraphio/papi-client";
import dayjs from "dayjs";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { LayoutBox } from "@/components/layout";
import ProgressStep from "./ProgressStep.vue";
import useFormPlugins from "@/composables/waybillFormPlugins";
import { useEdiBuilder } from "@/composables/ediBuilder";
import { usePapiClient } from "@/composables/papiClient";
import { FormKitNode, getNode, submitForm } from "@formkit/core";
import useNotificationStore from "@/stores/notifications";
import { useBapi } from "@/bapi-client";

import type { PatternInputDetails } from "@telegraphio/papi-client";
import type { InputOptionsData } from "@/types/ediBuilder";
import type { RudderstackEventData } from "@/types/rudderstack";

import TgBadge from "@/components/common/TgBadge.vue";
import TgButton from "@/components/common/TgButton.vue";
import SidebarButtonWithPopover from "./SidebarButtonWithPopover.vue";

const props = defineProps<{
  companyId: string;
  isAdvancedMode: boolean;
  patternId?: string;
  requestId?: string;
}>();

const inputDetails = ref<PatternInputDetails[]>([]);

const isPatternPage = computed(() => route.name === "createPattern" || !!props.patternId);
const isPatternEditable = computed(() => route.name === "createPattern" || route.name === "editPattern");
const isCreateTender = computed(() => route.name === "createTender");
const isViewTender = computed(() => route.name === "viewTenderRequest");
const isReplayTender = computed(() => route.name === "replayTenderRequest");

const route = useRoute();
const router = useRouter();
const notifier = useNotificationStore();
const trackers = useTrackerStore();
const { steps, buildPath, stepPlugin, inputPlugin, locationPlugin, commodityPlugin, partyPlugin, validationPlugin } =
  useFormPlugins(inputDetails, isPatternEditable.value);
const { papiApi } = usePapiClient();

const isNew = route.name === "createPattern";
const duplicatePatternId = route.query.duplicate as string | undefined;

const patternId = ref<string | undefined>(props.patternId);
const patternForm = ref();
const documentTitle = ref("");
const schemaMap = ref<Record<string, any>>({});
const isSaving = ref(false);
const tenderStatus = ref("");
const tenderAcknowledgement = ref<TenderAcknowledgement | undefined>(undefined);
const tenderAdvice = ref<TenderAdvice | undefined>(undefined);
const haveFetchTenderError = ref(false);
const tenderDocList = ref<{ label: string; value: number }[]>([]);
const selectedTenderDoc = ref<number | undefined>(undefined);
const loadingTender = ref(false);

const segmentOrder = [
  "general_shipment_information_BX",
  "rail_shipment_information_BNX",
  "release_M3",
  "extended_reference_information_N9",
  "equipment_details_N7_loop",
  "transaction_set_line_number_LX_loop",
  "party_identification_N1_loop",
  "origin_station_F9",
  "destination_station_D9",
  "route_information_R2",
  "protective_service_instructions_PS",
  "empty_car_disposition_pended_destination_consignee_E1_loop",
];

const sortedSchema = computed(() => {
  return Object.entries(schemaMap.value).sort(([a], [b]) => {
    const aIndex = segmentOrder.indexOf(a);
    const bIndex = segmentOrder.indexOf(b);

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });
});

const formOutput = computed(() => patternForm.value.node.value);
const firstStep = computed(() => Object.keys(steps)[0]);
const isPatternFormValid = computed(() => patternForm?.value?.node.context.state.valid);
const isTenderRejected = computed(() => tenderStatus.value.includes("reject") || tenderStatus.value.includes("error"));
const showFieldCustomizers = computed(() => isPatternEditable.value && props.isAdvancedMode);

const schemaData = ref({
  showCheckbox: showFieldCustomizers,
  variableCheckboxAttributes: {
    onChange: (e: Event) => {
      const target = e.target as HTMLInputElement;
      const id = target.id;

      const checkboxNode = getNode(id);

      if (!checkboxNode) {
        console.log("No checkbox node found for id:", id);
        return;
      }

      // Strip off '-checkbox' from the end of the name
      const fieldName = checkboxNode.name.slice(0, checkboxNode.name.length - 9);
      const node = checkboxNode.parent?.children.find((child) => child.name === fieldName);

      if (!node) {
        console.log("No input node found with name:", fieldName);
        return;
      }

      if (target.checked) {
        node.props.attrs.optionRequired = true;
      } else {
        delete node.props.attrs.optionRequired;
      }
    },
  },
  searchSttcs: async ({ search }: { search: string }) => {
    const result = await useBapi("getStccs", {
      searchTerm: search,
      context: "pricing",
    });

    if (!result.success) {
      return [];
    }

    const seenSTCCs = new Set<string>();

    return result.data.reduce(
      (acc, stcc) => {
        if (seenSTCCs.has(stcc.associatedSTCC)) {
          return acc;
        }

        seenSTCCs.add(stcc.associatedSTCC);

        const value = `${stcc.associatedSTCC} - ${stcc.commodity}`;

        return [...acc, { label: value, value }];
      },
      [] as { label: string; value: string }[],
    );
  },
  searchLocations: async ({ search }: { search: string }) => {
    const result = await useBapi("getAllLocations", {
      search,
    });

    if (!result.success) {
      return [];
    }

    return result.data.map((location) => {
      const formattedLocation = `${location.city}, ${location.state}, ${location.country}`;

      return {
        label: formattedLocation,
        value: formattedLocation,
      };
    });
  },
  searchJunctions: async ({ search, id }: { search: string; id: string }) => {
    if (!search) return [];

    const node = getNode(id) as FormKitNode;
    const parent = node.parent as FormKitNode;

    let scac = undefined;
    const scacNode = parent.children.find((child) => child.name === "standard_carrier_alpha_code_01");
    if (scacNode?.value) scac = scacNode.value as string;

    const result = await useBapi("getAllLocations", {
      search,
      scac,
      only_junctions: true,
    });

    if (!result.success) {
      return [];
    }

    return result.data.map((location) => location.junction_abbreviation);
  },
  searchScacs: async ({ search }: { search: string }) => {
    if (!search) return [];

    const result = await useBapi("autocompleteScacs", props.companyId, search);

    if (!result.success) {
      return [];
    }

    return result.data;
  },
  searchCifs: async ({ search }: { search: string }) => {
    try {
      const result = await papiApi.bookingGetCIFByFuzzyQuery({
        getCIFFuzzyQueryRequest: { term: search },
      });

      const { results = [] } = result;

      return results.map((customer) => {
        const { address1, address2, city, country, customerName, postal, state } = customer;

        return {
          label: `${customerName} - ${address1}, ${address2 ? address2 + ", " : ""}${city}, ${state}, ${postal}, ${country}`,
          value: customer,
        };
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getBillingScacs: async () => {
    try {
      const result = await papiApi.bookingGetAllowedReceivers({
        customerId: props.companyId,
      });
      return result.allowedScacs?.map((scac: string) => scac.toUpperCase()) ?? [];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
});

function updateVariableInputData(data: InputOptionsData) {
  if (data.label) {
    const node = getNode(data.fieldId);
    if (!node) return;

    node.props.attrs.originalLabel = node.props.label;
    node.props.label = data.label;
    node.props.labelClass = "text-blue-400";
    node.props.attrs.optionLabel = data.label;
  }
}

function resetInputData(fieldId: string) {
  const node = getNode(fieldId);
  if (!node) return;

  node.props.label = node.props.attrs.originalLabel;
  node.props.labelClass = "";
  delete node.props.attrs.optionLabel;
  delete node.props.attrs.originalLabel;
}

provide("editEdiFormData", {
  isVisible: showFieldCustomizers,
  updateVariableInputData,
  resetInputData,
});

function handleTrackCancel() {
  const rsEvent = isPatternEditable.value
    ? isNew
      ? RUDDERSTACK_EVENTS.WAYBILLING_NEW_PATTERN_CANCEL
      : RUDDERSTACK_EVENTS.WAYBILLING_EDIT_PATTERN_CANCEL
    : RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_CANCEL;

  trackers.logRudderstackEvent(rsEvent, { pattern_id: props.patternId });
}

function walkSchema() {
  const stack: FormKitNode[] = [patternForm.value.node];
  const inputList: PatternInputDetails[] = [];

  const excludeTypes = ["form", "group", "repeater"];

  while (stack.length) {
    const node = stack.pop();

    if (!node) continue;

    if (node.children.length) {
      node.children.forEach((child) => {
        stack.push(child as FormKitNode);
      });
      continue;
    }

    if (excludeTypes.includes(node.props.type) || node.name.endsWith("-checkbox") || node.name.endsWith("-lookup")) {
      continue;
    }

    const inputOptions: PatternInputDetails = {
      name: node.props.attrs.optionLabel ?? node.name,
      target: buildPath(node),
      optional: !node.props.attrs.optionRequired,
    };

    if (node.value) {
      inputOptions._default = node.value as string;
    }

    inputList.push(inputOptions);
  }

  return inputList;
}

function formatData(tender: Record<string, any>) {
  const stack: FormKitNode[] = [patternForm.value.node];

  // Set the lookup values to undefined so they don't get submitted
  tender.empty_car_disposition_pended_destination_consignee_E1_loop.forEach((entry: Record<string, unknown>) => {
    entry["disposition-lookup"] = undefined;
  });

  while (stack.length) {
    const node = stack.pop();

    if (!node) continue;
    if (!node.value) continue;

    if (node.children.length) {
      node.children.forEach((child) => {
        stack.push(child as FormKitNode);
      });
      continue;
    }

    if (node.props.type !== "date" && node.props.type !== "time") {
      continue;
    }

    let path = buildPath(node);
    path = path.replace(/\[/g, "").replace(/\]/g, "");

    const value = node.value as string;
    const dateValue =
      node.props.type === "date"
        ? new Date(`${value}T00:00:00Z`)
        : new Date(`${new Date().toISOString().slice(0, 11)}${value}:00Z`);

    path.split(".").reduce((acc, key, index, array) => {
      if (index === array.length - 1) {
        acc[key] = dateValue;
      } else {
        return acc[key];
      }
    }, tender);
  }
}

function trackTenderRequestEvent(event: keyof RudderstackEventData) {
  trackers.logRudderstackEvent(event, {
    status: tenderStatus.value,
    pattern_id: patternId.value,
    tender_request_id: props.requestId,
  });
}

function handleSubmit() {
  if (isPatternEditable.value) {
    handleSavePattern();
  } else if (isReplayTender.value) {
    handleReplayTender();
  } else {
    handleSubmitTender();
  }
}

async function handleSavePattern() {
  const rsEvent = isNew
    ? RUDDERSTACK_EVENTS.WAYBILLING_NEW_PATTERN_CREATE
    : RUDDERSTACK_EVENTS.WAYBILLING_EDIT_PATTERN_SAVE;

  try {
    isSaving.value = true;

    // The FormKit output contains nested duplicate keys, so we need to flatten it
    const pattern = Object.keys(formOutput.value).reduce((acc, key) => {
      return { ...acc, [key]: formOutput.value[key][key] };
    }, {});

    const inputDetailsList = walkSchema();

    const response = await papiApi.bookingUpsertPattern({
      customerId: props.companyId,
      upsertPatternRequest: {
        patternId: props.patternId,
        pattern: JSON.stringify(pattern),
        label: documentTitle.value,
        inputs: inputDetailsList,
      },
    });

    trackers.logRudderstackEvent(rsEvent, {
      success: true,
      pattern_id: isNew ? response.patternId : props.patternId,
      pattern_name: documentTitle.value,
    });

    notifier.setToast("success", "Pattern saved successfully");
    router.push({ name: "patternsList" });
  } catch (error) {
    trackers.logRudderstackEvent(rsEvent, {
      success: false,
      pattern_id: props.patternId,
    });

    notifier.setToast("danger", "Failed to save pattern");
    console.error(error);
  } finally {
    isSaving.value = false;
  }
}

async function handleSubmitTender() {
  try {
    isSaving.value = true;

    const tender = Object.keys(formOutput.value).reduce((acc, key) => {
      return { ...acc, [key]: formOutput.value[key][key] };
    }, {});

    formatData(tender);

    const response = await papiApi.bookingCreateTenderCustomer({
      customerId: props.companyId,
      createTenderRequest: {
        waybill: tender,
      },
    });

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_SUBMIT, {
      success: true,
      pattern_id: props.patternId,
      tender_request_id: response.requestId,
    });

    notifier.setToast("success", "Tender sumbitted successfully");
    router.push({ name: "waybillsList" });
  } catch (error) {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_SUBMIT, {
      success: false,
      pattern_id: props.patternId,
    });

    notifier.setToast("danger", "Failed to submit tender");
    console.error(error);
  } finally {
    isSaving.value = false;
  }
}

async function handleReplayTender() {
  try {
    isSaving.value = true;

    const tender = Object.keys(formOutput.value).reduce((acc, key) => {
      return { ...acc, [key]: formOutput.value[key][key] };
    }, {});

    formatData(tender);

    await papiApi.bookingReplay({
      replayRequest: {
        tender: {
          id: props.requestId,
          ...tender,
        },
        step: "STEP_FILE_DROP",
        search: {
          column: "request_id",
          searchValue: props.requestId,
        },
      },
    });

    trackTenderRequestEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_EDIT_RESUBMIT);

    notifier.setToast("success", "Replay request submitted");
    router.push({ name: "waybillsList" });
  } catch (error) {
    console.log(error);
    notifier.setToast("danger", "Failed to submit replay request");
  } finally {
    isSaving.value = false;
  }
}

async function fetchPattern() {
  let patternString = "";

  if (!isNew || (isNew && duplicatePatternId)) {
    notifier.setLoading("Loading pattern");

    const response = await papiApi.bookingGetPattern({
      customerId: props.companyId,
      getPatternRequest: { patternId: duplicatePatternId ?? props.patternId },
    });

    notifier.setLoading();

    const { label, pattern, inputs } = response;

    documentTitle.value = label ? (duplicatePatternId ? `${label} - DUPLICATE` : label) : "";
    patternString = pattern ?? "";
    inputDetails.value = inputs ?? [];
  }

  return patternString;
}

async function fetchTenderJson(fileId: number) {
  const { results: latestDoc = [] } = await papiApi.bookingGetWaybillFilesCustomer({
    customerId: props.companyId,
    getWaybillFilesRequest: { requestId: props.requestId, fileIds: [fileId] },
  });

  const doc = latestDoc[0].content?.trim();
  let jsonString;

  if (doc?.startsWith("{")) {
    // This is a JSON string, pass it through
    jsonString = doc;
  } else {
    // This is an EDI string, convert it to JSON
    const { result } = await papiApi.bookingConvertEDIToJson({
      conversionRequest: { data: latestDoc[0].content },
    });

    jsonString = result;
  }

  return jsonString;
}

async function fetchTenderRequest() {
  try {
    notifier.setLoading("Loading...");

    const [bookingRequest, { results: docList = [] }] = await Promise.all([
      papiApi.bookingGetBookingRequestStatus({
        customerId: props.companyId,
        requestId: props.requestId as string,
      }),
      papiApi.bookingListWaybillFilesCustomer({
        customerId: props.companyId,
        listWaybillFilesRequest: { requestId: props.requestId },
      }),
    ]);

    patternId.value = bookingRequest.patternId;
    tenderStatus.value = bookingRequest.state ?? "";
    tenderAdvice.value = bookingRequest.advice;
    tenderAcknowledgement.value = bookingRequest.acknowledgement;
    tenderDocList.value = docList
      .reduce((acc: { label: string; value: number }[], doc) => {
        if (doc.fileType === "json_404" || doc.fileType === "404") {
          acc.push({
            label: `ID: ${doc.id}, Created: ${dayjs(doc.createdDate).format("MMM D, YYYY - h:mm a")}`,
            value: doc.id as number,
          });

          return acc;
        }

        return acc;
      }, [])
      .reverse();

    const fileId = tenderDocList.value[0]?.value;
    selectedTenderDoc.value = fileId;

    const jsonString = await fetchTenderJson(fileId);
    return jsonString;
  } catch (error) {
    haveFetchTenderError.value = true;
    console.error(error);
  } finally {
    notifier.setLoading();
  }
}

watch(selectedTenderDoc, async (newId, oldId) => {
  if (!oldId || !newId) return;

  haveFetchTenderError.value = false;

  try {
    notifier.setLoading("Loading...");
    loadingTender.value = true;
    const jsonString = await fetchTenderJson(newId);

    const { rendered } = useEdiBuilder(jsonString || "", inputDetails.value, isPatternEditable.value);
    schemaMap.value = rendered;
  } catch (error) {
    haveFetchTenderError.value = true;
    console.error(error);
  } finally {
    notifier.setLoading();
    loadingTender.value = false;
  }
});

onMounted(async () => {
  const jsonString = isPatternPage.value ? await fetchPattern() : await fetchTenderRequest();

  const { rendered } = useEdiBuilder(jsonString || "", inputDetails.value, isPatternEditable.value);
  schemaMap.value = rendered;
});
</script>

<template>
  <div class="flex overflow-y-auto">
    <LayoutBox v-if="!isViewTender" class="flex w-52 flex-col justify-between p-3">
      <div class="mb-3 overflow-auto">
        <ProgressStep
          v-for="(step, key) in steps"
          :key="key"
          :label="key"
          :is-valid="unref(step.valid)"
          :is-submitted="unref(step.submitted)"
          :show-stem="key !== firstStep"
        />
      </div>
      <div class="flex flex-col gap-3">
        <SidebarButtonWithPopover
          v-if="isPatternEditable"
          v-model="documentTitle"
          entity="pattern"
          :is-loading="isSaving"
          :disabled="!isPatternFormValid"
          @save="submitForm('pattern-form')"
        >
          <TgButton class="w-full" is-small :disabled="!isPatternFormValid">Save Pattern</TgButton>
        </SidebarButtonWithPopover>
        <router-link v-else-if="isViewTender" :to="{ name: 'waybillsList' }">
          <TgButton
            is-small
            color="primary"
            class="w-full"
            @click="trackTenderRequestEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_VIEW_DONE)"
          >
            Done
          </TgButton>
        </router-link>
        <TgButton
          v-else
          data-testid="submit-tender"
          is-small
          :is-loading="isSaving"
          :disabled="haveFetchTenderError"
          color="primary"
          class="w-full"
          @click="submitForm('pattern-form')"
        >
          Submit
        </TgButton>
        <router-link
          v-if="isPatternPage || isReplayTender"
          :to="{ name: isPatternPage ? 'patternsList' : 'waybillsList' }"
        >
          <TgButton
            is-small
            class="w-full bg-red-200 hover:bg-red-300"
            @click="
              isReplayTender
                ? trackTenderRequestEvent(RUDDERSTACK_EVENTS.WAYBILLING_BOOKING_REQUEST_EDIT_CANCEL)
                : handleTrackCancel
            "
          >
            Cancel
          </TgButton>
        </router-link>
      </div>
    </LayoutBox>
    <div class="w-full overflow-y-scroll pl-3">
      <LayoutBox v-if="!isViewTender" class="mb-3 text-sm">
        <div class="flex">
          <div class="flex max-w-12 flex-grow items-center justify-center rounded-bl-md rounded-tl-md bg-orange">
            <i class="fa-solid fa-lg fa-triangle-exclamation px-2 text-white" />
          </div>
          <span class="p-3">
            Successful waybilling requires proper order data input from the User. Rejected or incorrect waybills due to
            inaccurate or unusable data submitted by the User may result in additional fees and costs. Telegraph
            disclaims any liability for inaccurate or unusable order data.
          </span>
        </div>
      </LayoutBox>
      <FormKit
        v-if="isViewTender || isReplayTender"
        v-model="selectedTenderDoc"
        type="select"
        label="Select Tender Request Document"
        :options="tenderDocList"
        outer-class="mb-6"
      />
      <LayoutBox v-if="isCreateTender || (isPatternEditable && isAdvancedMode)" class="mb-6 p-3">
        <ul v-if="isPatternEditable" class="list-disc">
          <li class="list-inside text-sm">Any fields filled out will persist when booking, but remain editable</li>
          <li class="list-inside text-sm">To require a field when booking, check the box next to it</li>
          <li class="list-inside text-sm">To edit a label, click any label that has a blue pencil</li>
        </ul>
        <ul v-else class="list-disc">
          <li class="list-inside text-sm">
            Any fields filled out have been taken from the pattern, but remain editable
          </li>
          <li class="list-inside text-sm">Fields with ✱ are required for submission</li>
        </ul>
      </LayoutBox>
      <div v-else>
        <TgBadge
          v-if="tenderAcknowledgement && tenderAcknowledgement.message"
          :status="isTenderRejected ? 'failure' : 'success'"
          class="mb-3"
        >
          <div class="text-base">
            <p><span class="font-bold">Code:</span> {{ tenderAcknowledgement.code }}</p>
            <p class="whitespace-break-spaces">
              <span class="font-bold">Message:</span> {{ tenderAcknowledgement.message }}
            </p>
          </div>
        </TgBadge>
        <TgBadge
          v-if="tenderAdvice && tenderAdvice.message"
          :status="isTenderRejected ? 'failure' : 'success'"
          class="mb-3"
        >
          <div class="text-base">
            <p v-if="isTenderRejected" class="font-bold">Tender Request Rejected</p>
            <p><span class="font-bold">Code:</span> {{ tenderAdvice.code }}</p>
            <p class="whitespace-break-spaces"><span class="font-bold">Message:</span> {{ tenderAdvice.message }}</p>
          </div>
        </TgBadge>
      </div>
      <FormKit
        v-if="!haveFetchTenderError && !loadingTender"
        id="pattern-form"
        ref="patternForm"
        type="form"
        :config="{ validationVisibility: isPatternEditable ? 'blur' : 'submit' }"
        :plugins="[stepPlugin, inputPlugin, locationPlugin, commodityPlugin, partyPlugin, validationPlugin]"
        :actions="false"
        :incomplete-message="false"
        :disabled="isViewTender"
        @submit="handleSubmit"
      >
        <section
          v-for="[groupName, node] in sortedSchema"
          :id="groupName"
          :key="groupName"
          class="waybill-form-group mb-12"
        >
          <FormKit :id="groupName" type="group" :name="groupName">
            <FormKitSchema :schema="node" :data="schemaData" />
          </FormKit>
        </section>
      </FormKit>
      <LayoutBox v-else-if="haveFetchTenderError" class="!bg-red-300 p-3 text-white">
        An error occurred while attempting to load the selected tender request. The tender request may be formatted
        incorrectly. Please try selecting a different tender request.
      </LayoutBox>
    </div>
  </div>
</template>
