import {
  BAPIInvoicingPayee,
  BAPIInvoicingCompany,
  InvoicingPayee,
  InvoicingCompany,
  DashboardInvoice,
  BAPIDashboardInvoice,
} from "@/bapi-client/types/external-invoicing";
import { centsToDollars, formatDollars } from "@/utils/currency";

export function invoicingPayeeFactory(data: BAPIInvoicingPayee): InvoicingPayee {
  const { addresses, ...theRest } = data;
  const address = data.addresses[0];
  return {
    ...theRest,
    address: {
      id: address?.id ?? "",
      address_1: address?.address_1 ?? "",
      address_2: address?.address_2 ?? "",
      city: address?.city ?? "",
      state: address?.state ?? "",
      country: address?.country ?? "",
      postal_code: address?.postal_code ?? "",
    },
  };
}

export function invoicingCompanyFactory(data: BAPIInvoicingCompany): InvoicingCompany {
  const { addresses, ...theRest } = data;
  const address = data.addresses[0];
  return {
    ...theRest,
    address: {
      id: address?.id ?? "",
      address_1: address?.address_1 ?? "",
      address_2: address?.address_2 ?? "",
      city: address?.city ?? "",
      state: address?.state ?? "",
      country: address?.country ?? "",
      postal_code: address?.postal_code ?? "",
    },
  };
}

export function invoiceListFactory(data: BAPIDashboardInvoice[]): DashboardInvoice[] {
  return data.map((invoice) => {
    const { charges, invoice_ar_total, invoice_ap_total, ...theRest } = invoice;
    const arTotalDollars = centsToDollars(invoice_ar_total ?? 0);
    const apTotalDollars = centsToDollars(invoice_ap_total ?? 0);
    return {
      ...theRest,
      invoice_ar_total,
      invoice_ap_total,
      invoice_ar_pretty_total: formatDollars(arTotalDollars),
      invoice_ap_pretty_total: formatDollars(apTotalDollars),
      charges: charges.map((charge) => {
        const apDollars = centsToDollars(charge.ap_amount_cents ?? 0);
        const arDollars = centsToDollars(charge.ar_amount_cents ?? 0);
        return {
          ...charge,
          ap_amount_dollars: apDollars,
          ap_pretty_amount: formatDollars(apDollars ?? 0),
          ar_amount_dollars: arDollars,
          ar_pretty_amount: formatDollars(arDollars),
        };
      }),
    };
  });
}
