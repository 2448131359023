import { BAPI_COMMANDS, BAPICommand } from "./types/commands";
import { submitInteledeskForm, templateHandlers, uploadS3File } from "./handlers";
import { getStccs } from "./handlers/stccHandler";
import { abort as fetchAbort } from "./fetch";
import {
  adminCreateCustomer,
  adminDeleteCustomer,
  adminGetCustomer,
  adminUpdateCustomer,
  adminAddCustomerEquipment,
  adminDeleteCustomerEquipment,
  adminGetCustomers,
} from "./handlers/internal-tools/customers";
import {
  adminListPermissions,
  adminGetCustomerPermissions,
  adminUpdateCustomerPermissions,
} from "./handlers/internal-tools/permissions";
import { getOrigins, getDestinations, getAllLocations } from "./handlers/locationHandlers";
import { getWaybillDetails, getWaybillDetailsActivityFeed } from "./handlers/waybills";
import {
  getMe,
  getProfilePictureUpload,
  loginUser,
  logoutUser,
  uploadUserProfilePicture,
  getPasswordResetEmail,
  resetUserPassword,
  getUserPermissions,
} from "./handlers/users";
import { autocompleteUsers } from "./handlers/autocomplete/users";
import { autocompleteAssets } from "./handlers/autocomplete/assets";
import { getAssetHistory, getVisibilityAssetsDownloadable, getVisiblityAssets } from "./handlers/assetHandler";
import {
  createTicket,
  deleteTicket,
  updateTicket,
  getTicket,
  getTickets,
  getTicketNotes,
  createTicketNote,
  getTicketNoteSignedUploadURL,
} from "./handlers/customer-support";
import { autocompleteScac } from "./handlers/autocomplete/scacs";
import { getReportingGroups } from "./handlers/reportingGroup";
import {
  deleteReport,
  getReportBuilderReport,
  getReportBuilderReportList,
  getReportDownload,
  getReportData,
  createReport,
  getReportSchedule,
  shareReport,
  saveReportSchedule,
  updateReport,
  previewReport,
  deleteReportSchedule,
} from "./handlers/report-builder/reports";
import {
  getInvoicingPayee,
  getInvoicingCompany,
  updateInvoicingPayee,
  updateInvoicingCompany,
  createInvoicingCompany,
  getAllExternalInvoices,
  createExternalInvoice,
  deleteExternalInvoice,
  getExternalInvoice,
  updateExternalInvoice,
  sendExternalInvoice,
  getDataFromWaybill,
  getInvoicePdfPreview,
  getChargeCodes,
  deleteChargeItem,
} from "./handlers/external-invoicing";
import { getInboundPipeline } from "./handlers/inboundPipelineHandler";
import { loginOAuthUser } from "./handlers/oauth";
import { getNotificationSettings, updateNotificationSettings } from "./handlers/notifications";
import {
  copyQuote,
  createNote,
  createQuote,
  deleteNote,
  deleteQuote,
  getActivityFeed,
  getNotes,
  getPricingHistoryReport,
  getPricingQuoteStats,
  getQuote,
  getQuotes,
  getUploadUrl,
  updateQuote,
} from "./handlers/pricing";

const BAPIHandlers = {
  [BAPI_COMMANDS.GET_REPORT_TEMPLATES]: templateHandlers.getReportTemplates,
  [BAPI_COMMANDS.GET_REPORT_TEMPLATE]: templateHandlers.getReportTemplate,
  [BAPI_COMMANDS.DELETE_REPORT_TEMPLATE]: templateHandlers.deleteReportTemplate,
  [BAPI_COMMANDS.CREATE_REPORT_TEMPLATE]: templateHandlers.createReportTemplate,
  [BAPI_COMMANDS.UPDATE_REPORT_TEMPLATE]: templateHandlers.updateReportTemplate,
  [BAPI_COMMANDS.GET_REPORT_TEMPLATE_FIELDS]: templateHandlers.getReportTemplateFields,
  [BAPI_COMMANDS.GET_STCCS]: getStccs,
  [BAPI_COMMANDS.ADMIN_GET_CUSTOMER]: adminGetCustomer,
  [BAPI_COMMANDS.ADMIN_GET_CUSTOMERS]: adminGetCustomers,
  [BAPI_COMMANDS.ADMIN_CREATE_CUSTOMER]: adminCreateCustomer,
  [BAPI_COMMANDS.ADMIN_UPDATE_CUSTOMER]: adminUpdateCustomer,
  [BAPI_COMMANDS.ADMIN_DELETE_CUSTOMER]: adminDeleteCustomer,
  [BAPI_COMMANDS.ADMIN_CUSTOMER_EQUIPMENT_ADD]: adminAddCustomerEquipment,
  [BAPI_COMMANDS.ADMIN_CUSTOMER_EQUIPMENT_DELETE]: adminDeleteCustomerEquipment,
  [BAPI_COMMANDS.ADMIN_LIST_PERMISSIONS]: adminListPermissions,
  [BAPI_COMMANDS.ADMIN_GET_CUSTOMER_PERMISSIONS]: adminGetCustomerPermissions,
  [BAPI_COMMANDS.ADMIN_UPDATE_CUSTOMER_PERMISSIONS]: adminUpdateCustomerPermissions,
  [BAPI_COMMANDS.GET_ORIGINS]: getOrigins,
  [BAPI_COMMANDS.GET_DESTINATIONS]: getDestinations,
  [BAPI_COMMANDS.GET_ALL_LOCATIONS]: getAllLocations,
  [BAPI_COMMANDS.GET_REPORTING_GROUPS]: getReportingGroups,
  [BAPI_COMMANDS.WAYBILL_GET_ACTIVITY_FEED]: getWaybillDetailsActivityFeed,
  [BAPI_COMMANDS.WAYBILL_GET_WAYBILL_DETAILS]: getWaybillDetails,
  [BAPI_COMMANDS.GET_VISIBILITY_ASSETS]: getVisiblityAssets,
  [BAPI_COMMANDS.GET_VISIBILITY_ASSETS_DOWNLOADABLE]: getVisibilityAssetsDownloadable,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET]: getTicket,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKETS]: getTickets,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET]: createTicket,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_DELETE_TICKET]: deleteTicket,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_UPDATE_TICKET]: updateTicket,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTES]: getTicketNotes,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET_NOTE]: createTicketNote,
  [BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTE_SIGNED_UPLOAD_URL]: getTicketNoteSignedUploadURL,
  [BAPI_COMMANDS.GET_ASSET_HISTORY]: getAssetHistory,
  [BAPI_COMMANDS.USER_GET_ME]: getMe,
  [BAPI_COMMANDS.USER_OAUTH_LOGIN]: loginOAuthUser,
  [BAPI_COMMANDS.USER_OAUTH_LOGOUT]: logoutUser,
  [BAPI_COMMANDS.USER_LOGIN]: loginUser,
  [BAPI_COMMANDS.USER_LOGOUT]: logoutUser,
  [BAPI_COMMANDS.USER_GET_PROFILE_PICTURE_UPLOAD_URL]: getProfilePictureUpload,
  [BAPI_COMMANDS.USER_UPLOAD_PROFILE_PICTURE]: uploadUserProfilePicture,
  [BAPI_COMMANDS.USER_GET_PASSWORD_RESET_EMAIL]: getPasswordResetEmail,
  [BAPI_COMMANDS.USER_RESET_PASSWORD]: resetUserPassword,
  [BAPI_COMMANDS.USER_PERMISSIONS]: getUserPermissions,
  [BAPI_COMMANDS.S3_UPLOAD_FILE]: uploadS3File,
  [BAPI_COMMANDS.AUTOCOMPLETE_USERS]: autocompleteUsers,
  [BAPI_COMMANDS.AUTOCOMPLETE_ASSETS]: autocompleteAssets,
  [BAPI_COMMANDS.AUTOCOMPLETE_SCACS]: autocompleteScac,
  [BAPI_COMMANDS.INTELEDESK_SUBMIT]: submitInteledeskForm,
  [BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_LIST]: getReportBuilderReportList,
  [BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_DETAILS]: getReportBuilderReport,
  [BAPI_COMMANDS.REPORT_BUILDER_DOWNLOAD_REPORT]: getReportDownload,
  [BAPI_COMMANDS.REPORT_BUILDER_DELETE_REPORT]: deleteReport,
  [BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_DATA]: getReportData,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_PAYEE]: getInvoicingPayee,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_COMPANY]: getInvoicingCompany,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_CREATE_COMPANY]: createInvoicingCompany,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_PAYEE]: updateInvoicingPayee,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_COMPANY]: updateInvoicingCompany,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_INVOICE]: updateExternalInvoice,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_ALL_INVOICES]: getAllExternalInvoices,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_DELETE_INVOICE]: deleteExternalInvoice,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_CREATE_INVOICE]: createExternalInvoice,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_INVOICE]: getExternalInvoice,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_SEND_INVOICE]: sendExternalInvoice,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_DATA_FROM_WAYBILL]: getDataFromWaybill,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_PDF_PREVIEW]: getInvoicePdfPreview,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_GET_CHARGE_CODES]: getChargeCodes,
  [BAPI_COMMANDS.EXTERNAL_INVOICING_DELETE_CHARGE_ITEM]: deleteChargeItem,
  [BAPI_COMMANDS.REPORT_BUILDER_CREATE_REPORT]: createReport,
  [BAPI_COMMANDS.REPORT_BUILDER_GET_REPORT_SCHEDULE]: getReportSchedule,
  [BAPI_COMMANDS.REPORT_BUILDER_SHARE_REPORT]: shareReport,
  [BAPI_COMMANDS.REPORT_BUILDER_SAVE_REPORT_SCHEDULE]: saveReportSchedule,
  [BAPI_COMMANDS.REPORT_BUILDER_DELETE_REPORT_SCHEDULE]: deleteReportSchedule,
  [BAPI_COMMANDS.REPORT_BUILDER_UPDATE_REPORT]: updateReport,
  [BAPI_COMMANDS.REPORT_BUILDER_PREVIEW_REPORT]: previewReport,
  [BAPI_COMMANDS.GET_PIPELINE_REPORT]: getInboundPipeline,
  [BAPI_COMMANDS.PRICING_GET_QUOTES]: getQuotes,
  [BAPI_COMMANDS.PRICING_GET_QUOTE]: getQuote,
  [BAPI_COMMANDS.PRICING_GET_PRICING_HISTORY_REPORT]: getPricingHistoryReport,
  [BAPI_COMMANDS.PRICING_GET_QUOTE_STATS]: getPricingQuoteStats,
  [BAPI_COMMANDS.PRICING_CREATE_QUOTE]: createQuote,
  [BAPI_COMMANDS.PRICING_UPDATE_QUOTE]: updateQuote,
  [BAPI_COMMANDS.PRICING_DELETE_QUOTE]: deleteQuote,
  [BAPI_COMMANDS.PRICING_COPY_QUOTE]: copyQuote,
  [BAPI_COMMANDS.PRICING_GET_NOTES]: getNotes,
  [BAPI_COMMANDS.PRICING_CREATE_NOTE]: createNote,
  [BAPI_COMMANDS.PRICING_DELETE_NOTE]: deleteNote,
  [BAPI_COMMANDS.PRICING_GET_ACTIVITY_FEED]: getActivityFeed,
  [BAPI_COMMANDS.PRICING_GET_UPLOAD_URL]: getUploadUrl,
  [BAPI_COMMANDS.NOTIFICATIONS_GET_SETTINGS]: getNotificationSettings,
  [BAPI_COMMANDS.NOTIFICATIONS_UPDATE_SETTINGS]: updateNotificationSettings,
} as const;

type HParam = { [K in BAPICommand]: Parameters<(typeof BAPIHandlers)[K]> };
type HReturn = { [K in BAPICommand]: ReturnType<(typeof BAPIHandlers)[K]> };
const mappedHandlers: { [K in BAPICommand]: (...args: HParam[K]) => HReturn[K] } = BAPIHandlers;

/** Provides access to BAPI commands by provided name and returns appropriate Result for the command
 *
 * @param name
 * Calls BAPI handler registered at provided name
 *
 * @param opts
 * Expected options and request body for returned handler
 */
export function useBapi<T extends keyof typeof BAPIHandlers>(name: T, ...args: HParam[T]) {
  const handler = mappedHandlers[name];
  return handler(...args);
}

export const abort = fetchAbort;
