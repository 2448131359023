import { setUser } from "@sentry/vue";
import dayjs from "dayjs";
import { ref, computed, watch } from "vue";
import { defineStore } from "pinia";
import { LocationQuery, useRouter } from "vue-router";
import useNotificationStore from "./notifications";
import useTrackerStore from "./trackers";
import { useAnalyticsStore } from "@/stores/analytics";
import { Customer, User } from "@/bapi-client/types/users";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { useLDClient } from "launchdarkly-vue-client-sdk";
import useFeatureStore from "./features";
import { clearOktaTokenStorage, getOktaConfig } from "@/utils/domainMappings";
import { useTheme } from "@/composables/useTheme";

export const useUserStore = defineStore("user", () => {
  const user = ref<User | undefined>();
  const company = ref<Customer | undefined>();
  const companyId = computed(() => {
    return company.value?.id ?? "";
  });
  const isUserVerified = ref(false);
  const lastVerified = ref();
  const initialToPath = ref("");
  const initialQueryParams = ref<LocationQuery>({});
  const permissions = ref<string[]>([]);
  const customerViewEnabled = ref(false);
  const notifier = useNotificationStore();
  const trackers = useTrackerStore();
  const featureStore = useFeatureStore();
  const router = useRouter();
  const ldClient = useLDClient();

  async function enableCustomerView() {
    if (!user.value?.isAdmin) {
      return undefined;
    }
    notifier.setLoading("Enabling customer view.");
    const cp = await useBapi("adminGetCustomerPermissions", companyId.value);
    notifier.setLoading();
    if (!cp.success) {
      notifier.setToast("danger", "Failed to load customer permissions: " + cp.error.status);
      return undefined;
    }
    permissions.value = cp.data.permissions.map((p) => p.name);
    notifier.setToast("success", "Customer view enabled.");
  }

  async function disableCustomerView() {
    notifier.setLoading("Disabling customer view.");
    const allPermissions = await useBapi("userPermissions", companyId.value);
    notifier.setLoading();
    if (!allPermissions.success) {
      notifier.setToast("danger", "Failed to load default permissions. Please refresh the page.");
      return undefined;
    }
    permissions.value = allPermissions.data;
    notifier.setToast("success", "Admin view re-enabled.");
  }

  watch(customerViewEnabled, async (nextView) => {
    if (nextView === true) {
      await enableCustomerView();
    } else {
      await disableCustomerView();
    }
  });

  const hasDashboardPermissions = computed(() => {
    const dashboardPermissions = [
      "dashboard_widgets_dwelling_over_2_days",
      "dashboard_widgets_ap_status",
      "dashboard_widgets_cp_status_or_notified",
      "dashboard_widgets_ready_for_release",
    ];

    return dashboardPermissions.some((p) => permissions.value.includes(p));
  });
  const hasVisibilityAssetListPermission = computed(() => permissions.value.includes("visibility_asset_list"));
  const hasUniversalSearchPermission = computed(() => permissions.value.includes("visibility_universal_search"));
  const hasReportingPermissions = computed(() => {
    const reportingPermissions = [
      "reporting_report_builder",
      "reporting_pipeline_view",
      "reporting_forecasting",
      "reporting_network_maps",
    ];

    return reportingPermissions.some((p) => permissions.value.includes(p));
  });

  const landingPage = computed(() => {
    if (
      (company.value?.roleId === 7 || company.value?.roleId === 8) &&
      permissions.value.includes("terminal_terminal")
    ) {
      return { name: "terminalLoadList" };
    }

    if (hasDashboardPermissions.value) {
      return { name: "dashboard" };
    }

    if (hasVisibilityAssetListPermission.value) {
      return {
        name: "assetsList",
        params: { companyId: companyId.value },
      };
    }

    // if (permissions.value.includes("waybilling_patterns")) {
    if (featureStore.features.hasWaybillModule) {
      return {
        name: "patternsList",
        params: { companyId: companyId.value },
      };
    }

    if (hasReportingPermissions.value) {
      return {
        name: "reportBuilderDashboard",
        params: { companyId: companyId.value },
      };
    }

    const analyticsStore = useAnalyticsStore();
    const reportId = analyticsStore.views[0]?.url;
    if (reportId) {
      return {
        name: "advancedAnalytics",
        params: { companyId: companyId.value },
        query: { report: encodeURIComponent(reportId) },
      };
    }

    // At this point, we have to assume the user has no permissions and have to simply redirect
    // them somewhere with no enforced permissions so that the error page can be displayed.
    return { name: "userProfile" };
  });

  async function loginUser(email: string, password: string): Promise<number> {
    notifier.setLoading("Logging in");
    const response = await useBapi(BAPI_COMMANDS.USER_LOGIN, {
      email,
      password,
    });
    notifier.setLoading();

    if (!response.success) {
      notifier.setToast("danger", "We were unable to log you in at this time.");
      console.error("Login failed: ", response.error.status);
      return response.error.status ?? 400;
    }

    const newUser = response.data;
    user.value = newUser;

    if (import.meta.env.PROD) {
      setUser({
        id: newUser.userId,
        username: `${newUser.firstName} ${newUser.lastName}`,
      });
    }

    if (!newUser.companies.length) {
      notifier.setToast("danger", "This account is not associated with any known customers.");
      console.error("No customer associations found for user ", email);
      return 401;
    }
    // Got a user! Set the current customer on state.
    isUserVerified.value = true;
    const localCompany = localStorage.getItem("defaultCompany");
    const defaultCompany = localCompany ? JSON.parse(localCompany) : user.value.companies[0].id;
    localStorage.setItem("authToken", user.value.token);
    await setUserCustomer(defaultCompany);
    await ldClient.identify({
      kind: "multi",
      user: {
        key: user.value.userId,
        name: user.value.firstName + " " + user.value.lastName,
        isAdmin: user.value.isAdmin,
        companyId: defaultCompany,
      },
      customer: {
        key: company.value?.id,
        name: company.value?.name,
        type: company.value?.type,
      },
    });
    trackers.addIdentityTrackers(email);
    lastVerified.value = dayjs();
    return 200;
  }
  async function logoutUser() {
    notifier.setLoading("Logging out");
    await useBapi(BAPI_COMMANDS.USER_LOGOUT);
    // Check if this domain has Okta configured
    const currentDomain = window.location.hostname;
    const hasOktaConfig = !!getOktaConfig(currentDomain);
    // Reset local state
    trackers.rudderstack?.resetIdentifier();
    user.value = undefined;
    company.value = undefined;
    isUserVerified.value = false;
    lastVerified.value = undefined;
    permissions.value = [];
    // Remove auth token
    localStorage.removeItem("authToken");
    // If Okta is configured for this domain, use OAuth logout flow
    if (hasOktaConfig) {
      notifier.setLoading();
      clearOktaTokenStorage();
      router.push({ name: "oauthLogout" });
      return;
    }
    notifier.setLoading();
    notifier.status = "ready";
    return;
  }

  async function verifyCurrentUser() {
    // No user to verify, get out.

    if (!localStorage.getItem("authToken") && !user.value) {
      // Check local storage for okta-token-storage
      const oktaTokenStorage = localStorage.getItem("okta-token-storage");
      if (oktaTokenStorage) {
        const oktaToken = JSON.parse(oktaTokenStorage);
        // Send user token to BAPI
        const response = await useBapi(BAPI_COMMANDS.USER_OAUTH_LOGIN, oktaToken);
        if (!response.success) {
          // Either way, no good wabtec session, so send them back to their login page
          console.error("OAuth session failure: ", response.error.status);
          // Send them to the OAuth failed login page with 401 or 403
          router.push({
            name: "oauthLoginFailed",
            query: { errorCode: (response.error.status || 401).toString() },
          });
          return undefined;
        }
        localStorage.setItem("authToken", response.data.token);
        user.value = response.data;
      } else {
        return undefined;
      }
    }

    if (user.value && lastVerified.value) {
      if (dayjs(lastVerified.value).isAfter(dayjs().subtract(15, "minutes"))) {
        return user.value;
      }
    }

    const response = await useBapi(BAPI_COMMANDS.USER_GET_ME);

    if (!response.success) {
      console.error(`Unable to verify user: ${response.error.status}`);
      // Since this token was invalid, we need to clear it out of local storage
      if (localStorage.getItem("authToken")) {
        localStorage.removeItem("authToken");
      }
      if (response.error.status === 401) {
        notifier.setToast("danger", "Your session has expired. Please log back in.");
        return undefined;
      }
      return undefined;
    }

    user.value = response.data;

    // Server somehow returned 200, but we didn't get a user.
    if (!user.value) {
      console.error("Unable to verify user.");
      return undefined;
    }

    // Got a user, but no customers!
    if (!user.value.companies.length) {
      console.error("No customer associations found for user.");
      notifier.setToast("danger", "This account is not associated with any known customers.");
      return undefined;
    }

    if (import.meta.env.PROD) {
      setUser({
        id: response.data.userId,
        username: `${response.data.firstName} ${response.data.lastName}`,
      });
    }

    localStorage.setItem("authToken", user.value.token);

    // Handle case where user hasn't been previously verified (new page visit w/ active session token)
    if (!isUserVerified.value) {
      const localCompany = localStorage.getItem("defaultCompany");
      const defaultCompany = localCompany ? JSON.parse(localCompany) : user.value.companies[0].id;
      const selectedCompany = await setUserCustomer(defaultCompany);
      trackers.addIdentityTrackers();
      await ldClient.identify({
        kind: "multi",
        user: {
          key: user.value.userId,
          name: user.value.firstName + " " + user.value.lastName,
          isAdmin: user.value.isAdmin,
          companyId: defaultCompany,
        },
        customer: {
          key: selectedCompany?.id,
          name: selectedCompany?.name,
          type: selectedCompany?.type,
        },
      });
    }

    isUserVerified.value = true;
    lastVerified.value = dayjs();
    return user.value;
  }

  async function setUserCustomer(customerId: string) {
    if (!user.value) {
      console.warn("Attempted to set user customer with no user present.");
      return undefined;
    }

    const customer = user.value.companies.find((c) => c.id === customerId);
    if (!customer) {
      notifier.setToast("danger", "This account does not have access to the provided customer.");
      console.error("User does not have access to customer ", customerId);
      company.value = user.value.companies[0];
      localStorage.setItem("defaultCompany", JSON.stringify(user.value.companies[0].id));
      return undefined;
    }

    company.value = customer;

    if (customer.integrations.tableau) {
      const analyticsStore = useAnalyticsStore();
      analyticsStore.token = customer.integrations.tableau.config?.token ?? "";
      analyticsStore.views = customer.integrations.tableau.views;
    }

    if (!customer.integrations.tableau) {
      const analyticsStore = useAnalyticsStore();
      analyticsStore.token = "";
      analyticsStore.views = [];
    }

    localStorage.setItem("defaultCompany", JSON.stringify(customerId));

    if (customerViewEnabled.value === true) {
      const cp = await useBapi("adminGetCustomerPermissions", companyId.value);
      if (cp.success) {
        permissions.value = cp.data.permissions.map((p) => p.name);
      }
    } else {
      const response = await useBapi(BAPI_COMMANDS.USER_PERMISSIONS, customer.id);
      if (response.success) {
        permissions.value = response.data;
      }
    }

    if (permissions.value.length || company.value.integrations?.tableau?.views?.length) {
      notifier.status = "ready";
      notifier.lastResponseCode = 0;
    } else {
      notifier.status = "fault";
      notifier.lastResponseCode = 403;
    }

    if (isUserVerified.value) {
      await ldClient.identify({
        kind: "multi",
        user: {
          key: user.value.userId,
          name: user.value.firstName + " " + user.value.lastName,
          isAdmin: user.value.isAdmin,
          companyId: customer.id,
        },
        customer: {
          key: customer.id,
          name: customer.name,
          type: customer.type,
        },
      });
      trackers.addIdentityTrackers();
    }

    localStorage.setItem("defaultTheme", customer.preferred_theme);
    if (customer.preferred_theme === "wabtec" || customer.preferred_theme === "staging") {
      const themeValue = customer.preferred_theme === "wabtec" ? "wabtec" : "dev";
      useTheme().setTheme(themeValue);
    } else {
      useTheme().setDefaultTheme();
    }
    return company.value;
  }

  return {
    user,
    companyId,
    company,
    loginUser,
    logoutUser,
    landingPage,
    isUserVerified,
    verifyCurrentUser,
    setUserCustomer,
    initialToPath,
    initialQueryParams,
    permissions,
    hasDashboardPermissions,
    hasVisibilityAssetListPermission,
    hasUniversalSearchPermission,
    hasReportingPermissions,
    enableCustomerView,
    disableCustomerView,
    customerViewEnabled,
  };
});
