import { defineStore } from "pinia";
import { computed, ref, shallowRef } from "vue";
import dayjs from "dayjs";
import { useFetch } from "@/composables/fetch";
import { useUserStore } from "./user";
import useNotificationStore from "./notifications";
import { toAlphanumeric } from "@/utils/text";
import useTrackerStore from "./trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { createCsv, downloadCsv } from "@/utils/csv";
import { useFiltered } from "@/composables/filtered";
import { useSorted } from "@/composables/sorted";
import { useReportingGroups } from "@/composables/reportingGroups";
import { PipelineReportAsset } from "@/bapi-client/types/assets/pipelineAsset";
import { useBapi, abort } from "@/bapi-client";

interface ReportingGroupDestination {
  id: string;
  name: string;
  searchTag: string;
}

export const usePipelineStore = defineStore("pipeline", () => {
  const reportingGroupDestinations = ref<ReportingGroupDestination[]>([]);
  const selectedReportingGroups = ref<string[]>([]);
  const selectedDestinations = ref<string[]>([]);
  // Yes it's a number but the API wants a string of a number
  // and our select menus don't support numbers right now.
  const maxAge = ref("2");
  const assets = shallowRef<PipelineReportAsset[]>([]);
  const userStore = useUserStore();
  const destinationsURL = computed(() => `/reports/${userStore.companyId}/destinations`);
  const count = ref(0);
  const today = ref(dayjs());
  const { filtered, filters, resetFilters, hasFilters } = useFiltered<PipelineReportAsset>(assets);
  const { sorted, sortColumn, sortDirection } = useSorted(filtered, "days_since_last_sighting", "DESC");
  const { reportingGroups, getReportingGroups } = useReportingGroups();

  filters.value = {
    days_since_last_sighting: { operand: "greater_than_or_equals", value: 0 },
    load_empty_status: { operand: "equals", value: "" },
    exceptions: { operand: "contains", value: [] },
    last_event_text: { operand: "contains", value: [] },
    commodity: { operand: "contains", value: [] },
    status_code: { operand: "contains", value: [] },
  };

  function reset() {
    const notifier = useNotificationStore();
    abort("getPipelineReport");
    abort("getReportingGroups");
    abort("getDestinations");
    resetFilters();
    notifier.stopAllLoaders();
    reportingGroupDestinations.value = [];
    selectedReportingGroups.value = [];
    selectedDestinations.value = [];
    maxAge.value = "2";
    count.value = 0;
    assets.value = [];
    sortColumn.value = "daysSinceLastSighting";
    sortDirection.value = "DESC";
  }

  const tableType = computed(() => {
    if (userStore.company?.waybillType === "both") {
      return "both";
    }
    return userStore.company?.waybillType === "R" ? "railcar" : "imdl";
  });

  async function getAssets() {
    const notifier = useNotificationStore();
    notifier.setLoading("Loading report");
    const response = await useBapi("getPipelineReport", userStore.companyId, {
      destination: selectedDestinations.value.join(","),
      group_ids: selectedReportingGroups.value,
      max_age: maxAge.value,
    });
    notifier.setLoading();

    if (!response.success) {
      if (response.error.aborted) {
        return undefined;
      }
      notifier.setToast("danger", "Unable to load report");
      return undefined;
    }

    assets.value = response.data.data;
    const trackers = useTrackerStore();
    const rsData = {
      groupNames: reportingGroups.value
        .reduce((acc: string[], curr) => {
          if (selectedReportingGroups.value.includes(curr.id)) {
            acc.push(curr.name);
          }
          return acc;
        }, [])
        .join(","),
      groupIds: selectedReportingGroups.value.join(","),
      destinationId: selectedDestinations.value.join(","),
      maxAge: maxAge.value,
      table: tableType.value,
      success: response.success,
      resultsCount: response.data.record_count,
    };
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_PIPELINE_GENERATE, rsData);
  }

  async function getDestinations() {
    const notifier = useNotificationStore();
    notifier.setLoading("Loading destinations");
    const request = useFetch();
    const response = await request.post(destinationsURL.value, {
      body: {
        group_ids: selectedReportingGroups.value,
      },
    });
    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Unable to load destinations at this time.");
      return undefined;
    }

    const data = await response.json();
    reportingGroupDestinations.value = data.data.map((dest: { id: string; name: string }) => ({
      id: dest.id,
      name: dest.name,
      searchTag: toAlphanumeric(dest.name),
    }));
  }

  function clearFilters() {
    resetFilters();
    sortColumn.value = ["teleETADate", "railETADate"];
    sortDirection.value = "ASC";
  }

  function prepareCsv() {
    const trackers = useTrackerStore();
    const groupNames = reportingGroups.value
      .reduce((acc: string[], curr) => {
        if (selectedReportingGroups.value.includes(curr.id)) {
          acc.push(curr.name);
        }
        return acc;
      }, [])
      .join(",");

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.ANALYTICS_PIPELINE_DOWNLOAD_CSV, {
      groupIds: selectedReportingGroups.value,
      groupNames,
      destinationId: selectedDestinations.value.join(","),
      maxAge: maxAge.value,
      table: tableType.value,
    });

    const headers =
      tableType.value === "railcar"
        ? [
            "Asset ID",
            "L/E",
            "Reporting Group(s)",
            "Commodity",
            "Current Location",
            "Last Event",
            "Last Event Date",
            "Destination",
            "Arrival Date",
            "Telegraph ETA",
            "Railroad ETA",
            "Current Carrier",
            "Origin",
            "Dwell",
            "Last Note",
          ]
        : [
            "Asset ID",
            "L/E",
            "Reporting Group(s)",
            "BOL #",
            "Last Event",
            "Last Event Date",
            "Current Location",
            "Origin",
            "Destination",
            "Arrival Date",
            "Telegraph ETA",
            "Railroad ETA",
            "Current Carrier",
            "Notified",
            "Pickup #",
            "Train ID",
            "Dwell",
            "Last Note",
          ];

    const list = sorted.value.length ? filtered : assets;
    const rows = [];
    for (const asset of list.value) {
      const data = [];
      const dateFormatter = (date: string) => dayjs(date).format("MM/DD/YYYY HH:mm");
      const groupNames = [];
      for (const groupId of asset.reporting_groups) {
        const name = reportingGroups.value.find((group) => group.id === groupId)?.name;
        if (name) {
          groupNames.push(name);
        }
      }

      const arrivalDate =
        asset.status_code > 50 && asset.last_sighting_date ? dateFormatter(asset.last_sighting_date) : "";

      const teleETA = asset.status_code < 50 && asset.tele_eta?.date ? dateFormatter(asset.tele_eta.date) : "";

      const railETA = asset.status_code < 50 && asset.rail_eta?.date ? dateFormatter(asset.rail_eta.date) : "";

      data.push(asset.equipment_id);
      data.push(asset.load_empty_status);
      data.push(`"${groupNames.join(",")}"`);

      if (tableType.value === "railcar") {
        data.push(`"${asset.commodity}"`);
        data.push(`"${asset.current_location}"`);
        data.push(`${asset.last_event_text}`);
        data.push(`${asset.last_sighting_date ? dateFormatter(asset.last_sighting_date) : ""}`);
        data.push(`"${asset.destination_location}"`);
        data.push(arrivalDate);
        data.push(teleETA);
        data.push(railETA);
        data.push(asset.current_carrier);
        data.push(`"${asset.origin_location}"`);
        data.push(asset.days_since_last_sighting);
        data.push(`"${asset.note ? asset.note.note : ""}"`);
        rows.push(data);
        continue;
      }

      data.push(asset.bill_of_lading_number ? asset.bill_of_lading_number : "");
      data.push(asset.last_event_text);
      data.push(asset.last_sighting_date ? dateFormatter(asset.last_sighting_date) : "");
      data.push(`"${asset.current_location}"`);
      data.push(`"${asset.origin_location}"`);
      data.push(`"${asset.destination_location}"`);
      data.push(arrivalDate);
      data.push(teleETA);
      data.push(railETA);
      data.push(asset.current_carrier);
      data.push(asset.last_notify_date ? dateFormatter(asset.last_notify_date) : "");
      data.push(asset.imdl_pickup_number ? asset.imdl_pickup_number : "");
      data.push(asset.train_id ? asset.train_id : "");
      data.push(`${asset.days_since_last_sighting}`);
      data.push(asset.note ? `"${asset.note?.note}"` : "");
      rows.push(data);
    }

    const csv = createCsv(headers, rows);
    downloadCsv(`pipeline-report-${dayjs().format("MM-DD-YYYY")}.csv`, csv);
  }

  return {
    reset,
    reportingGroups,
    getReportingGroups,
    reportingGroupDestinations,
    selectedReportingGroups,
    selectedDestinations,
    tableType,
    today,
    maxAge,
    count,
    assets,
    filtered,
    filters,
    clearFilters,
    sorted,
    hasFilters,
    sortColumn,
    sortDirection,
    getAssets,
    getDestinations,
    prepareCsv,
  };
});
