<script setup lang="ts">
import { computed } from "vue";
import type { BAPICustomerSupportTicket } from "@/bapi-client/types/customer-support";
import { ProfilePicture } from "../common";
import PopoverTag from "../common/PopoverTag.vue";
import TicketTableAssignee from "./TicketTableAssignee.vue";
import { useUserStore } from "@/stores/user";
import useNotificationStore from "@/stores/notifications";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";

type Props = {
  ticket: BAPICustomerSupportTicket;
  customerId: string;
  reportingGroups?: string[];
};

const props = defineProps<Props>();
const userStore = useUserStore();
const notifier = useNotificationStore();
const trackers = useTrackerStore();

const displayLabels = computed(() => {
  const equipmentIds: string[] = [];
  const reportingGroups: string[] = [];
  if (props.ticket.ticket_assets) {
    for (const asset of props.ticket.ticket_assets) {
      equipmentIds.push(asset.equipment_id);
      if (!asset.reporting_group_name_map) continue;
      for (const rg of asset.reporting_group_name_map) {
        for (const id in rg) {
          reportingGroups.push(rg[id]);
        }
      }
    }
  }
  return { equipmentIds, reportingGroups };
});
</script>

<template>
  <tr class="whitespace-nowrap bg-white text-base font-medium leading-none">
    <td class="border p-3 text-center text-xl">
      <span
        :class="{
          'text-red-500': ticket.priority === 'high',
          'text-orange': ticket.priority === 'medium',
          'text-yellow-500': ticket.priority === 'low',
        }"
      >
        <i class="fa-solid fa-fire-flame-simple"></i>
      </span>
    </td>
    <td class="border py-3 pl-6 pr-3">
      <router-link
        :to="{ name: 'ticket', params: { customerId, ticketId: ticket.id } }"
        class="text-blue hover:text-blue-700 hover:underline"
        @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.CUSTOMER_SUPPORT_TICKET_VIEW, { ticket_id: ticket.id })"
      >
        {{ ticket.qualified_id }}
      </router-link>
    </td>
    <td class="max-w-96 overflow-hidden overflow-ellipsis whitespace-nowrap border py-3 pl-6 pr-3" :title="ticket.name">
      {{ ticket.name }}
    </td>
    <td class="border py-3 pl-6 pr-3 text-left">
      <div class="flex items-center gap-1.5">
        <template v-if="displayLabels.reportingGroups.length">
          <span>{{ displayLabels.reportingGroups[0] }}</span>
        </template>
        <template v-if="displayLabels.reportingGroups.length > 1">
          <PopoverTag
            :name="ticket.name + '-reporting-groups'"
            :label="`+ ${displayLabels.reportingGroups.length - 1}`"
          >
            <ul class="divide-y">
              <template v-for="(group, idx) in displayLabels.reportingGroups" :key="group">
                <li v-if="idx > 0" class="p-3">{{ group }}</li>
              </template>
            </ul>
          </PopoverTag>
        </template>
      </div>
    </td>
    <td class="border py-3 pl-6 pr-3 text-left">
      <template v-if="displayLabels.equipmentIds.length">
        <div class="flex items-center gap-1.5">
          <span>{{ displayLabels.equipmentIds[0] }}</span>
          <PopoverTag
            v-if="displayLabels.equipmentIds.length > 1"
            :name="ticket.name + '-waybill-equipment'"
            :label="`+ ${displayLabels.equipmentIds.length - 1}`"
          >
            <ul class="divide-y">
              <template v-for="(asset, idx) in displayLabels.equipmentIds" :key="asset">
                <li v-if="idx > 0" class="p-3">{{ asset }}</li>
              </template>
            </ul>
          </PopoverTag>
        </div>
      </template>
    </td>
    <td class="border py-3 pl-6 pr-3">
      {{ ticket.railroads[0] }}
    </td>
    <td class="w-6 border pl-6">{{ ticket.loaded_empty ?? "" }}</td>
    <td class="w-60 border py-3 pl-6 pr-3">
      <template v-if="ticket.tags?.length > 1">
        <div class="flex items-center gap-1.5">
          <span>{{ ticket.tags[0] }}</span>
          <PopoverTag :name="ticket.name + '-tags'" :label="`+ ${ticket.tags.length - 1}`">
            <ul class="divide-y">
              <template v-for="(tag, idx) in ticket.tags" :key="tag">
                <li v-if="idx > 0" class="p-3">{{ tag }}</li>
              </template>
            </ul>
          </PopoverTag>
        </div>
      </template>
      <template v-if="ticket.tags?.length === 1">
        {{ ticket.tags[0] }}
      </template>
    </td>
    <td class="border py-3 pl-6 pr-3">{{ $date("MM/DD/YY", ticket.created_ts) }}</td>
    <td class="border py-3 pl-6 pr-3">
      {{ $date("MM/DD/YY", ticket.created_ts) }}
    </td>
    <td class="border py-3 pl-6 pr-3 capitalize">{{ ticket.state }}</td>
    <td class="border py-3 pl-6 pr-3">
      <template v-if="ticket.assignees.length">
        <div class="flex items-center gap-1.5">
          <ProfilePicture
            v-if="ticket.assignees[0]?.profile_picture"
            :url="ticket.assignees[0]?.profile_picture.thumbnail_url"
            :initials="`${ticket.assignees[0].first_name.charAt(0)}${ticket.assignees[0].last_name.charAt(0)}`"
            size="2"
          />
          <p>{{ ticket.assignees[0].first_name + " " + ticket.assignees[0].last_name }}</p>
        </div>
      </template>
      <TicketTableAssignee
        v-if="!ticket.assignees?.length && ticket.state !== 'Closed'"
        :ticket="ticket"
        :current-user="userStore.user?.userId ?? ''"
        @success="notifier.setToast('success', `Ticket ${$event} assigned!`)"
        @error="notifier.setToast('danger', 'Unable to assign ticket.')"
      />
    </td>
  </tr>
</template>
