import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import type { BAPIParams } from "@/bapi-client/types/bapiParams";
import { stccFactory } from "@/factories/stccFactory";
import { fetchHandler } from "@/bapi-client/fetch";
import { BAPIError } from "@/bapi-client/errors";
import { Result } from "../types";
import { type STCC } from "../types/stccs";
import { type ParamsGetSTCCs } from "../types/common/stccCode";

export async function getStccs(opts: ParamsGetSTCCs): Promise<Result<STCC[]>> {
  let path = "";

  if (!opts.stccCodes && !opts.searchTerm) {
    return { success: false, error: new BAPIError("Missing required fields, stccCodes or searchTerm is required") };
  }

  if (opts.stccCodes?.length && opts.searchTerm?.length) {
    return { success: false, error: new BAPIError("Can only query by one of searchTerm or stccCodes at a time.") };
  }

  if (!opts.customerId && opts.context === "reports") {
    return { success: false, error: new BAPIError("customerId is required for STCC lookup in the current context.") };
  }

  if (opts.context === "pricing") {
    path = "/pricing/stccs";
  }

  if (opts.context === "reports") {
    path = `/reports/${opts.customerId}/stccs`;
  }

  const body: Partial<BAPIParams[typeof BAPI_COMMANDS.GET_STCCS]> = opts.searchTerm
    ? { search_term: opts.searchTerm }
    : { stcc_codes: opts.stccCodes };

  const response = await fetchHandler(BAPI_COMMANDS.GET_STCCS, "POST", path, {
    canAbort: true,
    body: body,
  });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const parsed = await data.json();

  if (!parsed.success) {
    return parsed;
  }

  return {
    success: true,
    data: parsed.data.stcc_codes.map(stccFactory),
  };
}
