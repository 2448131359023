import { RouteRecordRaw } from "vue-router";

const LoginPage = () => import("@/pages/login/LoginPage.vue");
const LoginFormPage = () => import("@/pages/login/LoginFormPage.vue");
const LoginForgotPasswordPage = () => import("@/pages/login/LoginForgotPasswordPage.vue");
const LoginResetPasswordPage = () => import("@/pages/login/LoginResetPasswordPage.vue");

export const loginRoutes: RouteRecordRaw = {
  name: "loginPage",
  path: "/login",
  component: LoginPage,
  meta: {
    authenticated: false,
  },
  children: [
    {
      name: "login",
      path: "",
      component: LoginFormPage,
      meta: {
        title: "Login",
      },
      props: true,
    },
    {
      name: "forgotPassword",
      path: "forgot",
      component: LoginForgotPasswordPage,
      meta: {
        title: "Forgot Password",
      },
      props: true,
    },
    {
      name: "resetPassword",
      path: "reset",
      component: LoginResetPasswordPage,
      meta: {
        title: "Reset Password",
      },
      props: true,
      beforeEnter: (to) => {
        if (!to.query.token) {
          return { name: "login" };
        }
      },
    },
  ],
};
