<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel, TransitionRoot } from "@headlessui/vue";
import { useFloating, autoPlacement } from "@floating-ui/vue";
import { ref } from "vue";

interface Props {
  id: string;
  name: string;
  singular: string;
  plural: string;
  disabled: boolean;
  typedEntriesCount: number;
  searchedForEntriesCount: number;
  placeholder: string;
}

const searchTerms = defineModel<string>({ default: "" });

defineProps<Props>();
const emit = defineEmits<{
  (event: "search"): void;
  (event: "clear"): void;
}>();

const reference = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(reference, floating, {
  strategy: "absolute",
  middleware: [
    autoPlacement({
      alignment: "start",
      autoAlignment: true,
      allowedPlacements: ["bottom-start", "bottom", "bottom-end"],
    }),
  ],
});

async function search(cb: () => void) {
  emit("search");
  cb();
}
</script>

<template>
  <Popover v-slot="{ open }" as="div" class="relative w-full">
    <PopoverButton
      v-bind="$attrs"
      ref="reference"
      :disabled="disabled"
      class="placeholder: w-full focus-visible:outline-0 disabled:cursor-not-allowed"
    >
      <button
        :disabled="disabled"
        :is-open="open"
        :class="{
          'rounded-md border-b': !open,
          'rounded-none rounded-tl-md rounded-tr-md border-b-0': open,
        }"
        class="inline-flex h-8 w-full cursor-pointer items-center justify-between gap-3 rounded-md border border-blue p-3 text-base font-medium text-blue transition-colors hover:bg-gray-100 focus:shadow-inner active:shadow-inner disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
      >
        <span v-if="searchedForEntriesCount === 1">{{ searchTerms.split("\n")[0] }}</span>
        <span v-else-if="searchedForEntriesCount > 1" class="rounded-full bg-gray-300 px-4 text-sm">
          {{ searchedForEntriesCount + " " + plural }} Selected</span
        >
        <span v-else>{{ singular }} Search</span>
        <div class="flex items-center gap-2">
          <span
            v-if="searchedForEntriesCount <= 1"
            class="z-20 ml-4 rounded-full bg-gray-300 px-4 text-sm"
            :class="{ '!bg-gray-200 text-gray-400': searchedForEntriesCount === 0 }"
          >
            {{ searchedForEntriesCount }}
          </span>
          <span class="text-sm">
            <i class="fa-regular fa-pen-field" />
          </span>
        </div>
      </button>
    </PopoverButton>
    <TransitionRoot :show="open" as="div" class="relative">
      <PopoverPanel
        ref="floating"
        v-slot="{ close }"
        :style="floatingStyles"
        static
        :class="{
          '!rounded-none': open,
        }"
        class="absolute z-40 w-full rounded-md bg-white"
      >
        <FormKit
          :id="id"
          v-model="searchTerms"
          :name="name"
          type="textarea"
          :classes="{
            inner: '!mb-0 !ring-0 !border !border-r !border-blue',
            input: 'placeholder:whitespace-pre-wrap',
          }"
          :placeholder="placeholder"
        />
        <div>
          <p class="border-l border-r border-blue bg-white text-center text-sm font-semibold">
            {{ typedEntriesCount }} {{ typedEntriesCount === 1 ? singular : plural }}
          </p>
        </div>
        <button
          v-if="searchedForEntriesCount > 0"
          class="h-9 w-full border-l border-r border-t border-blue text-center font-semibold text-red-500 active:shadow-inner"
          @click.prevent="$emit('clear')"
        >
          Reset
        </button>
        <button
          class="c h-9 w-full rounded-bl-md rounded-br-md bg-blue text-center font-semibold text-white hover:bg-blue-600 focus:shadow-inner focus:shadow-blue-900 active:shadow-inner active:shadow-blue-900"
          @click.prevent="search(close)"
        >
          Search
        </button>
      </PopoverPanel>
    </TransitionRoot>
  </Popover>
</template>
