<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import useNotificationStore from "@/stores/notifications";
import TgButton from "@/components/common/TgButton.vue";
import ErrorPage from "./ErrorPage.vue";
export default defineComponent({
  name: "App401Error",
  components: { ErrorPage, TgButton },
  setup() {
    const router = useRouter();

    function goBack(): void {
      const notifier = useNotificationStore();
      notifier.status = "ready";
      router.push({ name: "login" });
    }

    onMounted(() => {
      setTimeout(() => {
        const notifier = useNotificationStore();
        notifier.status = "ready";
        router.push({ name: "login" });
      }, 10000);
    });

    return { goBack };
  },
});
</script>
<template>
  <ErrorPage>
    <template #title>Login required</template>
    <template #message>
      You need to log in to access this page. If you think you've reached this page in error, please let us know!
    </template>
    <template #controls>
      <TgButton class="m-4" :is-rounded="true" color="primary" @click="goBack">Log in</TgButton>
    </template>
  </ErrorPage>
</template>
